<template>
    <div class="wrap">
        <div class="page_title"><b>案件详情</b><el-button class="closeBtn" type="primary" size="mini" icon="el-icon-close" circle @click="goBack"></el-button></div>
        <div class="page_content clearfix">
            <!-- <div :class="{ cards_content: 1, cards_left: 1, fl: 1, 'cards_left-width': 1 }"> -->
            <div :class="{ cards_content: 1 }">
                <!-- 案件详情 -->
                <case-info :caseDetailData="caseDetailData"></case-info>
                <!-- 案件材料 -->
                <case-materia :caseDetailData="caseDetailData"></case-materia>
            </div>
            <!-- 案件日志 -->
            <div :class="{ cards_right: 1, fr: 1, 'cards_right-width': 1 }" v-if="0">
                <case-log ref="caseLogRef" :caseDetailData="caseDetailData" @hiddenCaseLog="hiddenCaseLog"></case-log>
            </div>
            <!-- 操作 -->
            <div class="operates">
                <el-button type="primary" @click="operateCase('chesun')">车损质检</el-button>
                <el-button type="primary" @click="operateCase('renshang')">人伤质检</el-button>
                <el-button :disabled="nextCaseDisabled" @click="nextCaseDetail">下个案件</el-button>
            </div>
        </div>
        <!-- 质检弹窗 -->
        <el-dialog class="zhi-dialog" :title="switchStr == 'renshang' ? '人伤质检' : '车损质检'" :visible.sync="zhiDialogVisible" width="750px" append-to-body center @close="beforeClose">
            <el-form class="dialog-box autoC clearfix" :model="zhiRuleForm" :rules="zhiRules" ref="zhiRuleForm" label-width="120px">
                <div :class="zhiRuleForm.injuryRiskCase || zhiRuleForm.vehicleRiskCase ? 'dialog-left fl' : 'dialog-left autoC'">
                    <el-form-item label="事故编号：">
                        <span>{{ zhiRuleForm.accidentNumber }}</span>
                    </el-form-item>
                    <template v-if="switchStr == 'renshang'">
                        <el-form-item label="是否风险案件：" prop="injuryRiskCase">
                            <el-radio-group v-model="zhiRuleForm.injuryRiskCase">
                                <el-radio :label="0">否</el-radio>
                                <el-radio :label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </template>
                    <template v-else>
                        <el-form-item label="是否风险案件：" prop="vehicleRiskCase">
                            <el-radio-group v-model="zhiRuleForm.vehicleRiskCase">
                                <el-radio :label="0">否</el-radio>
                                <el-radio :label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </template>
                    <template v-if="switchStr == 'renshang' && zhiRuleForm.injuryRiskCase">
                        <el-form-item label="可减损金额：" prop="injuryReduceAmount">
                            <el-input class="resetLineHeight" v-model.number="zhiRuleForm.injuryReduceAmount" type="number" oninput="if(value.length>10)value=value.slice(0,10)"></el-input>
                        </el-form-item>
                        <el-form-item label="估损金额：" prop="injuryEstimateAmount">
                            <el-input class="resetLineHeight" v-model.number="zhiRuleForm.injuryEstimateAmount" type="number" oninput="if(value.length>10)value=value.slice(0,10)"></el-input>
                        </el-form-item>
                    </template>
                    <template v-else-if="switchStr == 'chesun' && zhiRuleForm.vehicleRiskCase">
                        <el-form-item label="可减损金额：" prop="vehicleReduceAmount">
                            <el-input class="resetLineHeight" v-model.number="zhiRuleForm.vehicleReduceAmount" type="number" oninput="if(value.length>10)value=value.slice(0,10)"></el-input>
                        </el-form-item>
                        <el-form-item label="估损金额：" prop="vehicleEstimateAmount">
                            <el-input class="resetLineHeight" v-model.number="zhiRuleForm.vehicleEstimateAmount" type="number" oninput="if(value.length>10)value=value.slice(0,10)"></el-input>
                        </el-form-item>
                    </template>
                    <el-form-item label="备注项：" prop="remark">
                        <el-input :class="zhiRuleForm.riskCase ? 'resetLineHeight' : 'resetLineHeight'" v-model="zhiRuleForm.remark" type="textarea" :rows="4"></el-input>
                    </el-form-item>
                </div>
                <div class="dialog-right fr" v-if="switchStr == 'renshang' && zhiRuleForm.injuryRiskCase">
                    <div class="right-div">
                        <el-form-item class="newLeft" label="人伤风险点：" prop="injuryCategory1" required>
                            <div class="right-div">
                                <el-select v-model="zhiRuleForm.injuryCategory1" placeholder="一级风险点" clearable>
                                    <template v-for="item in riskTypeOption">
                                        <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                                    </template>
                                </el-select>
                            </div>
                        </el-form-item>
                        <el-form-item class="newLeft" label="" prop="injuryCategory2" required>
                            <div class="right-div">
                                <el-select v-model="zhiRuleForm.injuryCategory2" placeholder="二级风险点" multiple clearable>
                                    <template v-for="item in riskTypeOption2">
                                        <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                                    </template>
                                </el-select>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="dialog-right fr" v-else-if="switchStr == 'chesun' && zhiRuleForm.vehicleRiskCase">
                    <el-form-item class="none-left" label="车损风险点：" prop="category3" required> </el-form-item>
                    <div class="right-div">
                        <el-select v-model="zhiRuleForm.category1" placeholder="一级风险" @change="getCategory(1)">
                            <template v-for="item in category1Option">
                                <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div class="right-div">
                        <el-select v-model="zhiRuleForm.category2" placeholder="二级风险" @change="getCategory(2)">
                            <template v-for="item in category2Option">
                                <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div class="right-div">
                        <el-select v-model="zhiRuleForm.category3" placeholder="具体项" multiple clearable>
                            <template v-for="item in category3Option">
                                <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitZhiForm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import CaseInfo from '@/components/staging/caseInfo.vue';
import CaseMateria from '@/components/staging/caseMateria.vue';
export default {
    components: {
        CaseInfo,
        CaseMateria
    },
    props: {},
    data() {
        return {
            nextCaseDisabled: false,
            caseDetailData: null,
            zhiDialogVisible: false,
            switchStr: '',
            zhiRuleForm: {
                accidentNumber: '',
                riskCase: null,
                reduceAmount: '',
                estimateAmount: '',
                injuryReduceAmount: '',
                injuryEstimateAmount: '',
                injuryReduceAmount: '',
                injuryEstimateAmount: '',
                totalPayAmount: '',
                vehicleRiskCase: 0,
                injuryRiskCase: 0,
                category1: '',
                category2: '',
                category3: [],
                injuryCategory1: '',
                injuryCategory2: [],
                remark: null
            },
            zhiRules: {
                reduceAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                estimateAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                totalPayAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                injuryReduceAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                injuryEstimateAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                vehicleReduceAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                vehicleEstimateAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                injuryCategory1: [{ required: true, message: '请选择风险点', trigger: 'change' }],
                injuryCategory2: [{ required: true, message: '请选择风险点', trigger: 'change' }],
                category3: [{ required: true, message: '请选择完整风险点', trigger: 'change' }],
                desc: [{ required: true, message: '请填写案件号', trigger: 'blur' }]
            },
            riskTypeOption: [],
            riskTypeOption2: [],
            category1Option: [],
            category2Option: [],
            category3Option: [],
            queryForm: {},
            rowIds: [],
            rowIndex: -1
        };
    },
    mounted() {
        if (this.$route.query) {
            let tmpCaseId = JSON.parse(this.$route.query.caseDetail).id;
            this.queryForm = JSON.parse(this.$route.query.queryForm);
            this.rowIndex = this.$route.query.rowIndex;
            this.rowIds = this.$route.query.rowIds;

            if (this.queryForm.page * this.queryForm.size >= this.queryForm.total) {
                if (this.rowIndex == this.rowIds.length - 1) {
                    this.nextCaseDisabled = true;
                }
            }

            this.getCaseDetail(tmpCaseId);
        }
        this.getCategory(0);
        this.getCategory(2);
        // 人伤风险类型1 枚举
        this.getPublicEnum('CASE_QUALITY_HUMAN_INJURY_RISK_01', 'riskTypeOption');
        // 人伤风险类型2 枚举
        this.getPublicEnum('CASE_QUALITY_HUMAN_INJURY_RISK_02', 'riskTypeOption2');
    },
    methods: {
        getCaseList() {
            this.instance.post('/evcardQuality/searchCaseQuality', this.queryForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    let tableData = data.data.list;
                    let rowIds = [];
                    tableData.forEach((item) => {
                        rowIds.push(item.id);
                    });
                    this.$router.push({ path: '/staging/caseDetail', query: { caseDetail: JSON.stringify({ id: this.rowIds[this.rowIndex] }), queryForm: JSON.stringify(this.queryForm), rowIndex: this.rowIndex, rowIds: this.rowIds } });
                }
            });
        },
        beforeClose() {
            this.zhiDialogVisible = false;
            this.$refs.zhiRuleForm.resetFields();
            this.zhiRuleForm = {
                accidentNumber: '',
                riskCase: null,
                reduceAmount: '',
                estimateAmount: '',
                injuryReduceAmount: '',
                injuryEstimateAmount: '',
                injuryReduceAmount: '',
                injuryEstimateAmount: '',
                totalPayAmount: '',
                vehicleRiskCase: 0,
                injuryRiskCase: 0,
                category1: '',
                category2: '',
                category3: [],
                injuryCategory1: '',
                injuryCategory2: [],
                remark: null,
                riskCase: 0
            };
        },
        goBack() {
            sessionStorage.setItem('queryForm', JSON.stringify(this.queryForm));
            this.$router.push({ path: '/staging/qualityEvcard' });
        },
        submitZhiForm() {
            this.$refs.zhiRuleForm.validate((validate) => {
                if (validate) {
                    this.instance.post(this.switchStr == 'chesun' ? '/evcardQuality/vehicleQuality' : '/evcardQuality/injuryQuality', this.zhiRuleForm).then((res) => {
                        let data = res.data;
                        if (data.code == 1) {
                            // this.searchCaseQuality();
                            this.$message.success('当前案件质检成功');
                            this.beforeClose();
                        } else {
                            this.$message.error(data.message);
                        }
                    });
                }
            });
        },
        getCategory(num) {
            switch (num) {
                case 1:
                    this.zhiRuleForm.category2 = '';
                    this.zhiRuleForm.category3 = [];
                    break;
                case 2:
                    this.zhiRuleForm.category3 = [];
                    break;

                default:
                    break;
            }

            this.getCaseQualityCategory(num);
        },
        operateCase(name) {
            this.switchStr = name;
            this.zhiDialogVisible = true;
            let row = this.caseDetailData;
            if (this.switchStr == 'renshang') {
                this.zhiRuleForm = {
                    id: row.id,
                    accidentNumber: row.accidentNumber,
                    injuryRiskCase: row.injuryRiskCase ? row.injuryRiskCase : 0,
                    reduceAmount: row.reduceAmount ? row.reduceAmount : '',
                    estimateAmount: row.estimateAmount ? row.estimateAmount : '',
                    totalPayAmount: row.totalPayAmount ? row.totalPayAmount : '',
                    injuryCategory1: row.injuryCategory1 ? row.injuryCategory1 : '',
                    injuryCategory2: row.injuryCategory2 ? row.injuryCategory2 : [],
                    injuryReduceAmount: row.injuryReduceAmount ? row.injuryReduceAmount : '',
                    injuryEstimateAmount: row.injuryEstimateAmount ? row.injuryEstimateAmount : '',
                    vehicleReduceAmount: row.vehicleReduceAmount ? row.vehicleReduceAmount : '',
                    vehicleEstimateAmount: row.vehicleEstimateAmount ? row.vehicleEstimateAmount : '',
                    remark: row.remark ? row.remark : '',
                    riskCase: 0
                };
            } else {
                this.zhiRuleForm = {
                    id: row.id,
                    accidentNumber: row.accidentNumber,
                    vehicleRiskCase: row.vehicleRiskCase ? row.vehicleRiskCase : 0,
                    category1: row.category1 ? row.category1 : '',
                    category2: row.category2 ? row.category2 : '',
                    category3: row.category3 ? row.category3 : [],
                    reduceAmount: row.reduceAmount ? row.reduceAmount : '',
                    estimateAmount: row.estimateAmount ? row.estimateAmount : '',
                    totalPayAmount: row.totalPayAmount ? row.totalPayAmount : '',
                    injuryCategory1: row.injuryCategory1 ? row.injuryCategory1 : '',
                    injuryCategory2: row.injuryCategory2 ? row.injuryCategory2 : [],
                    injuryReduceAmount: row.injuryReduceAmount ? row.injuryReduceAmount : '',
                    injuryEstimateAmount: row.injuryEstimateAmount ? row.injuryEstimateAmount : '',
                    vehicleReduceAmount: row.vehicleReduceAmount ? row.vehicleReduceAmount : '',
                    vehicleEstimateAmount: row.vehicleEstimateAmount ? row.vehicleEstimateAmount : '',
                    remark: row.remark ? row.remark : '',
                    riskCase: 0
                };
                if (row.category1) {
                    this.getCaseQualityCategory(1);
                }
                if (row.category2) {
                    this.getCaseQualityCategory(2);
                }
            }
        },
        nextCaseDetail() {
            if (this.rowIndex < this.rowIds.length - 1) {
                this.rowIndex++;
                this.$router.push({ path: '/staging/caseDetail', query: { caseDetail: JSON.stringify({ id: this.rowIds[this.rowIndex] }), queryForm: JSON.stringify(this.queryForm), rowIndex: this.rowIndex, rowIds: this.rowIds } });
            } else {
                this.rowIndex = 0;
                this.queryForm.page++;
                this.getCaseList();
            }
        },
        getCaseQualityCategory(num) {
            this.instance.get(`/caseQuality/getCaseQualityCategory${num + 1}`, { params: { ['category' + num]: this.zhiRuleForm['category' + num] } }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        item.metaData = Number(item.metaData);
                    });
                    this['category' + (num + 1) + 'Option'] = data.data;
                }
            });
        },
        getCaseDetail(caseId) {
            this.instance.get('/evcardQuality/getAccidentDetail', { params: { accidentId: caseId } }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    let caseDetail = data.data;
                    if (Object.keys(caseDetail).length > 0) {
                        let tmpFileList = caseDetail.materialsList;
                        let b = [];
                        for (var key in tmpFileList) {
                            if (tmpFileList.hasOwnProperty(key)) {
                                var files = tmpFileList[key].map((item) => ({ materialName: item }));
                                b.push({ accidentNumber: data.data.accidentNumber, parentName: key, files: files });
                            }
                        }

                        caseDetail.tmpMaterialsList = b;
                    }
                    this.caseDetailData = caseDetail;
                }
            });
        }
    }
};
</script>
<style>
@import '../../assets/css/table_public.css';
@import '../../assets/css/caseCard.css';
</style>
<style lang="less" scoped>
.page_title {
    margin-bottom: 20px;
    .closeBtn {
        float: right;
    }
}
.page_content {
    ::-webkit-scrollbar {
        width: 0px;
        height: 10px;
    }
    .cards_left {
        position: relative;
        .btn_groups {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 5;
        }
        .activeBtns {
            position: fixed;
            top: 100px;
            right: 22%;
            z-index: 5;
        }
    }
    .operates {
        position: fixed;
        left: 50%;
        bottom: 50px;
        transform: translateX(-50%);
    }
}
// 弹窗
.dialog_content {
    .el-row {
        margin-bottom: 30px;
        .title_col {
            text-align: right;
        }
    }
    .el-row:last-child {
        margin-bottom: 0;
    }
}
.process_popup {
    .el-form-item__content {
        .el-select {
            width: 100%;
        }
    }
}

.textareaNumPosition {
    /deep/ .el-input__count,
    .el-input__count,
    /deep/ span {
        background: none !important;
        bottom: -25px !important;
        right: 3px !important;
    }
}

.zhi-dialog {
    /deep/ .el-dialog__footer {
        margin-top: -20px;
    }
    .dialog-right {
        .right-div {
            margin-bottom: 20px;
        }

        .none-left {
            margin-left: 0 !important;
            /deep/ .el-form-item__error {
                margin-left: -120px;
            }
            /deep/ label {
                text-align: left !important;
            }
        }

        .newLeft {
            .right-div {
                margin-bottom: 5px;
            }
        }
    }
}
</style>