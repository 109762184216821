<template>
    <div>
        <!-- 录入案件弹窗 -->
        <el-dialog title="录入诉讼案件" :visible.sync="dialogEnterFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="750px" top="5vh" @close="closeDiag">
            <div class="dialog_content">
                <el-form class="query_case_form" :model="queryCaseForm" :rules="queryCaseRules" ref="queryCaseRef" label-width="150px">
                    <el-form-item :class="{ form_item: 1, exist_case: isCaseExist }" label="报案号或美团案件号：" prop="caseNo">
                        <el-row>
                            <el-col class="exist_col" :span="15">
                                <el-input :class="{ errorBorder: queryInputCase }" v-model.trim="queryCaseForm.caseNo" clearable @blur="checkLawsuitCase"></el-input>
                                <div class="exist_message" v-if="isCaseExist">该报案号已录入过</div>
                            </el-col>
                            <template v-if="!manualEdit">
                                <el-col :span="3" :offset="2"><el-button type="primary" @click="searchMeiTuanCase">查询</el-button></el-col>
                                <el-col :span="3"><el-button type="primary" @click="manualEntry(true)">手动录入</el-button></el-col>
                            </template>
                            <el-col :span="3" :offset="5" v-else><el-button type="primary" @click="manualEntry(false)">取消录入</el-button></el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item class="form_item" label="" v-show="0">
                        <el-input v-model.trim="queryCaseForm.caseNo" clearable></el-input>
                    </el-form-item>
                </el-form>
                <el-form :model="addCaseForm" :rules="rules" ref="ruleForm" label-width="140px">
                    <div class="case_show" v-if="!manualEdit">
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-row>
                                    <el-col class="left_col" :span="10">
                                        <div class="info_title txt_box query_title">保单号：</div>
                                    </el-col>
                                    <el-col :span="14">
                                        <div class="txt_box">{{ queryCaseData && queryCaseData.policyNo ? queryCaseData.policyNo : '--' }}</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col class="title_col" :span="12">
                                <el-row>
                                    <el-col :span="10">
                                        <div class="info_title txt_box query_title">出险时间：</div>
                                    </el-col>
                                    <el-col :span="14">
                                        <div class="txt_box">{{ queryCaseData && queryCaseData.accidentTime ? queryCaseData.accidentTime : '--' }}</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-row>
                                    <el-col class="left_col" :span="10">
                                        <div class="info_title txt_box query_title">保险产品类型：</div>
                                    </el-col>
                                    <el-col :span="14">
                                        <div class="txt_box">{{ queryCaseData && queryCaseData.productType ? queryCaseData.productType : '--' }}</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col class="title_col" :span="12">
                                <el-row>
                                    <el-col :span="10">
                                        <div class="info_title txt_box query_title">险种：</div>
                                    </el-col>
                                    <el-col :span="14">
                                        <div class="txt_box">{{ queryCaseData && queryCaseData.insureTypeName ? queryCaseData.insureTypeName : '--' }}</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-row>
                                    <el-col class="left_col" :span="10">
                                        <div class="info_title txt_box query_title">骑手：</div>
                                    </el-col>
                                    <el-col :span="14">
                                        <div class="txt_box">{{ queryCaseData && queryCaseData.nameOfDeliveryMan ? queryCaseData.nameOfDeliveryMan : '--' }}</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col class="title_col" :span="12">
                                <el-row>
                                    <el-col :span="10">
                                        <div class="info_title txt_box query_title">投保人名称：</div>
                                    </el-col>
                                    <el-col :span="14">
                                        <div class="txt_box">{{ queryCaseData && queryCaseData.insuranceApplicant ? queryCaseData.insuranceApplicant : '--' }}</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="8">
                                <el-row>
                                    <el-col class="left_col" :span="10">
                                        <div class="info_title txt_box query_title">出险省：</div>
                                    </el-col>
                                    <el-col :span="8" :offset="5">
                                        <div class="txt_box">{{ queryCaseData && queryCaseData.accidentProvince ? queryCaseData.accidentProvince : '--' }}</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col class="title_col" :span="8">
                                <el-row>
                                    <el-col class="left_col" :span="10">
                                        <div class="info_title txt_box query_title">出险市：</div>
                                    </el-col>
                                    <el-col :span="8" :offset="5">
                                        <div class="txt_box">{{ queryCaseData && queryCaseData.accidentCity ? queryCaseData.accidentCity : '--' }}</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col class="title_col" :span="8">
                                <el-row>
                                    <el-col class="left_col" :span="10">
                                        <div class="info_title txt_box query_title">出险区：</div>
                                    </el-col>
                                    <el-col :span="8" :offset="5">
                                        <div class="txt_box">{{ queryCaseData && queryCaseData.accidentDistrict ? queryCaseData.accidentDistrict : '--' }}</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-row>
                                    <el-col class="left_col" :span="10">
                                        <div class="info_title txt_box query_title">出险省份：</div>
                                    </el-col>
                                    <el-col :span="14">
                                        <div class="txt_box">{{ queryCaseData && queryCaseData.accidentCityDistrict ? queryCaseData.accidentCityDistrict : '--' }}</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="form_content case_entry" v-else>
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="保单号：" prop="policyNo">
                                    <el-input v-model.trim="addCaseForm.policyNo"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="出险时间：" prop="accidentTime">
                                    <el-date-picker class="width_inherit" v-model="addCaseForm.accidentTime" type="date" placeholder="选择日期"> </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="保险产品类型：" prop="productType">
                                    <el-select v-model="addCaseForm.productType" placeholder="请选择">
                                        <el-option v-for="(item, index) in productTypeOptions" :key="index" :label="item" :value="item"> </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="险种：" prop="insureTypeName">
                                    <el-select v-model="addCaseForm.insureTypeName" placeholder="请选择">
                                        <el-option v-for="(item, index) in insureTypeOptions" :key="index" :label="item" :value="item"> </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="骑手：" prop="nameOfDeliveryMan">
                                    <el-input v-model.trim="addCaseForm.nameOfDeliveryMan"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="投保人名称：" prop="insuranceApplicant">
                                    <el-input v-model.trim="addCaseForm.insuranceApplicant"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="24">
                                <div class="province_box">
                                    <el-form-item class="province_item" label="出险省份：" prop="province">
                                        <el-select v-model="addCaseForm.province" placeholder="请选择" clearable filterable @change="provinceChange1">
                                            <el-option v-for="item in provinceOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName + '-' + item.areaCode"> </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item class="province_item" label="" prop="city">
                                        <el-select v-model="addCaseForm.city" placeholder="请选择" :disabled="cityDisabled" clearable filterable @change="provinceChange2">
                                            <el-option v-for="item in cityOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName + '-' + item.areaCode"> </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item class="province_item" label="" prop="district">
                                        <el-select v-model="addCaseForm.district" placeholder="请选择" :disabled="districtDisabled" clearable filterable>
                                            <el-option v-for="item in districtOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName + '-' + item.areaCode"> </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row v-if="0">
                            <el-col class="title_col" :span="24">
                                <el-form-item label="出险省份：" prop="accidentCityDistrict">
                                    <el-input v-model.trim="addCaseForm.accidentCityDistrict"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="form_content">
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="案件名称：">
                                    <el-input v-model.trim="addCaseForm.caseName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="法院案号：">
                                    <el-input v-model.trim="addCaseForm.courtCaseNo"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="原告：" prop="plaintiff">
                                    <el-input v-model.trim="addCaseForm.plaintiff"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="诉讼金额：" prop="lawsuitAmount">
                                    <el-input
                                        v-model.trim="addCaseForm.lawsuitAmount"
                                        maxlength="11"
                                        oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="诉讼原因：" prop="lawsuitCause">
                                    <el-select v-model="addCaseForm.lawsuitCause" filterable clearable placeholder="请选择">
                                        <el-option v-for="item in causeOptions" :key="item.id" :label="item.dictName" :value="item.dictName"> </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="分配：">
                                    <el-select v-model="addCaseForm.distributeHandlerUser" placeholder="请选择" clearable :disabled="!btnMenuData[200]" @change="allocationUserChange">
                                        <el-option v-for="item in allocationUserOptions" :key="item.userId" :label="item.nickName" :value="item.userName"> </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="法院：">
                                    <el-input v-model="addCaseForm.court"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="法官：">
                                    <el-input v-model="addCaseForm.judge"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="12">
                                <el-form-item label="开庭时间：">
                                    <el-date-picker v-model="addCaseForm.courtTime" placeholder="请选择日期" clearable type="datetime" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%"> </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="24">
                                <el-form-item label="情况：" prop="situation">
                                    <el-input v-model="addCaseForm.situation" type="textarea" :autosize="{ minRows: 4 }" placeholder="请输入内容"> </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="updateFile" v-for="(fileItem, fileIndex) in files" :key="fileIndex">
                            <el-col class="title_col" v-if="fileIndex == 0" :span="3" :offset="2"> <span style="margin-left: 4px">上传附件：</span> </el-col>
                            <el-col :span="5" :offset="fileIndex > 0 ? 5 : 0">
                                <el-select v-model="fileItem.typeCode" filterable clearable placeholder="请选择文件类型" size="mini">
                                    <el-option v-for="item in caseFileTypeOptions" :key="item.code" :label="item.name" :value="item.code"> </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="2" :offset="1">
                                <el-upload :ref="'upload' + fileIndex" action="#" :http-request="(e) => ossUpload(e)" multiple :limit="1" :on-exceed="(e, e1) => handleExceed(fileItem, fileIndex, e, e1)" :show-file-list="false">
                                    <el-button type="primary" size="mini" :disabled="Boolean(fileItem.fileName)" @click="currentData(fileItem, fileIndex)">选择文件</el-button>
                                </el-upload>
                            </el-col>
                            <el-col :span="6" :offset="2">
                                <el-tooltip v-if="fileItem.flag" class="item" effect="dark" :content="fileItem.fileName" placement="top-start">
                                    <div :class="{ preview_file: 1, ellipsis: 1, ['ellipsisEnterCase' + fileIndex]: 1 }">
                                        <span :class="{ tooltipText: fileItem.isPreview }" v-if="fileItem.fileExtension && fileItem.fileExtension == 'pdf'" @click="openFileInNewTab(fileItem.previewPath)">{{ fileItem.fileName }}</span>
                                        <span :class="{ tooltipText: fileItem.isPreview }" v-else>{{ fileItem.fileName }}</span>
                                        <el-image class="preview_image" v-if="fileItem.fileExtension && fileItem.fileExtension != 'pdf'" :src="fileItem.previewPath" :preview-src-list="[fileItem.previewPath]"> </el-image>
                                    </div>
                                </el-tooltip>
                                <div :class="{ preview_file: 1, ellipsis: 1, ['ellipsisEnterCase' + fileIndex]: 1 }" v-else-if="fileItem.fileName">
                                    <span :class="{ tooltipText: fileItem.isPreview }" v-if="fileItem.fileExtension && fileItem.fileExtension == 'pdf'" @click="openFileInNewTab(fileItem.previewPath)">{{ fileItem.fileName }}</span>
                                    <span :class="{ tooltipText: fileItem.isPreview }" v-else>{{ fileItem.fileName }}</span>

                                    <el-image class="preview_image" v-if="fileItem.fileExtension && fileItem.fileExtension != 'pdf' && fileItem.uploadProgress == 100" :src="fileItem.previewPath" :preview-src-list="[fileItem.previewPath]"> </el-image>
                                </div>
                                <span class="fontSeat" v-else>占</span>
                                <span class="progressSpan" v-if="fileItem.fileName">{{ fileItem.uploadProgress }} %</span>
                            </el-col>
                            <el-col class="del_Col" :span="1" :offset="2">
                                <i :class="files.length == 1 && fileIndex == 0 ? 'errorBtn del_btn el-icon-delete' : 'errorBtn del_btn el-icon-delete'" v-if="files.length > 1 || (fileIndex == 0 && fileItem.fileName)" @click="delFile(fileItem, fileIndex)"></i>
                                <!-- <div class="disabledBtn"></div> -->
                                <!-- v-if="fileItem.fileName && fileItem.uploadProgress != 100 && !fileItem.delFlag" -->
                            </el-col>
                        </el-row>
                        <el-row v-if="files.length < 10">
                            <el-col class="title_col" :span="5" :offset="5">
                                <el-button class="uploadBtn" size="mini" @click="addFile">添加附件</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="enterCase(true)">创 建</el-button>
                <el-button @click="enterCase(false)">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import OSS from 'ali-oss';
import { v4 as uuidv4 } from 'uuid';

let queryCaseRuleFlag = false;
const validatePass = (rule, value, callback) => {
    if (value.length > 0 && queryCaseRuleFlag) {
        callback(new Error('未查询到案件号，请输入正确的理赔报案号重新查询'));
    } else {
        callback();
    }
};
export default {
    props: {
        dialogShow: {
            type: Boolean,
            default: false
        },
        btnMenuData: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            isCaseExist: false,
            manualEdit: false,
            creatCaseFlag: false,
            baseURL: '',
            queryInputCase: false,
            queryCaseFlag: false,
            dialogEnterFlag: false,
            currentFileIndex: -1,
            OSSClient: null,
            addCaseForm: {
                province: '',
                city: '',
                district: '',
                policyNo: '',
                accidentTime: '',
                productType: '',
                insureTypeName: '',
                nameOfDeliveryMan: '',
                insuranceApplicant: '',
                accidentCityDistrict: '',
                //
                caseNo: '',
                caseName: '',
                courtCaseNo: '',
                plaintiff: '',
                lawsuitAmount: '',
                lawsuitCause: '',
                distributeHandler: '',
                distributeHandlerUser: '',
                situation: '',
                accidentProvince: '',
                accidentCity: '',
                accidentDistrict: ''
            },
            queryCaseData: {
                policyNo: null,
                productType: null,
                insureTypeName: null,
                accidentCityDistrict: null,
                nameOfDeliveryMan: null
            },
            queryCaseForm: {
                caseNo: ''
            },
            province: '',
            provinceOptions: [],
            city: '',
            cityOptions: [],
            district: '',
            districtOptions: [],
            cityDisabled: true,
            districtDisabled: true,
            causeOptions: [],
            productTypeOptions: [],
            insureTypeOptions: [],
            allocationUserOptions: [],
            allocationUserObj: {},
            caseFileTypeOptions: [],
            caseFileTypeObj: {},
            files: [
                {
                    typeCode: '',
                    typeName: '占位',
                    fileName: '',
                    ossPath: '',
                    flag: false,
                    isPreview: false
                }
            ],
            queryCaseRules: {
                caseNo: [
                    { required: true, message: '请输入理赔报案案件号', trigger: ['blur', 'change'] },
                    { required: true, trigger: 'blur', validator: validatePass }
                ]
            },
            rules: {
                province: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                city: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                district: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                policyNo: [{ required: true, message: '请输入保单号', trigger: ['blur', 'change'] }],
                accidentTime: [{ required: true, message: '请选择出险时间', trigger: ['blur', 'change'] }],
                productType: [{ required: true, message: '请选择保险产品类型', trigger: ['blur', 'change'] }],
                insureTypeName: [{ required: true, message: '请选择险种', trigger: ['blur', 'change'] }],
                nameOfDeliveryMan: [{ required: true, message: '请输入骑手', trigger: ['blur', 'change'] }],
                insuranceApplicant: [{ required: true, message: '请输入投保人名称', trigger: ['blur', 'change'] }],
                accidentCityDistrict: [{ required: true, message: '请输入出险省份', trigger: ['blur', 'change'] }],
                //
                caseName: [{ required: true, message: '请输入案件名称', trigger: ['blur', 'change'] }],
                plaintiff: [{ required: true, message: '请输入原告', trigger: ['blur', 'change'] }],
                lawsuitAmount: [{ required: true, message: '请输入诉讼金额', trigger: ['blur', 'change'] }],
                lawsuitCause: [{ required: true, message: '请选择诉讼原因', trigger: ['blur', 'change'] }]
            },
            fileTypeList: ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif']
        };
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
        // 诉讼原因
        this.getCause();
        // 保险产品类型
        this.getProductType();
        // 险种类型
        this.getInsureTypeOptions();
        // 查询分配用户
        this.getAllocationUser();
        // 文件类型
        this.getCaseFileType();
        // 查询省市
        this.findProvince();
    },
    methods: {
        findProvince() {
            this.instance.post('/area/findProvince', {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.provinceOptions = data.data;
                }
            });
        },
        findCity() {
            let code = this.addCaseForm.province.split('-')[1];
            this.instance.post('/area/findCity/' + code, {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.cityDisabled = false;
                    this.districtDisabled = false;
                    this.cityOptions = data.data;
                }
            });
        },
        findDistrict() {
            let code = this.addCaseForm.city.split('-')[1];
            this.instance.post('/area/findDistrict/' + code, {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.districtDisabled = false;
                    this.districtOptions = data.data;
                }
            });
        },
        provinceChange1(val) {
            this.addCaseForm.city = '';
            this.addCaseForm.district = '';
            this.cityDisabled = true;
            this.districtDisabled = true;
            if (val) {
                this.findCity();
            }
        },
        provinceChange2(val) {
            this.addCaseForm.district = '';
            this.districtDisabled = true;
            if (val) {
                this.findDistrict();
            }
        },
        //OSS直传
        ossUpload(e, fileType, retryFlag) {
            const OSS = require('ali-oss');
            let _this = this;
            let file = e.file;
            let storeAs = 'upload-file';
            let protocol = location.protocol;
            let tmpSuffix = file.name.split('.')[1];
            let fileTypeList = ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'];
            let tmpImageUrl = null;
            let uuidStr = uuidv4();

            const reader = new FileReader();
            reader.readAsDataURL(file); // 将文件转换成 Base64 格式
            reader.onloadend = () => {
                tmpImageUrl = reader.result; // 保存 Base64 URL 地址
            };

            console.log('-4');
            console.log('index:' + this.currentFileIndex);
            console.log(this.files);
            console.log(uuidStr);

            if (this.files[this.currentFileIndex]) {
                this.files[this.currentFileIndex].uploadProgress = 0;
                this.files[this.currentFileIndex].fileName = file.name;
                this.files[this.currentFileIndex].uuidStr = uuidStr;
            }

            // if (fileType && fileType != tmpSuffix) {
            //     this.$message.error('请选择正确文件类型');
            //     return;
            // }

            // if (!fileType && fileTypeList.indexOf(tmpSuffix) == -1) {
            //     this.$message.error('请选择正确文件类型');
            //     return;
            // }
            console.log('-1');
            console.log(this.files);

            // OSS.urllib是SDK内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向sts-server发送请求。
            this.instance.get(protocol + _this.baseURL + '/aliyunoss/access', { params: {} }).then((res) => {
                if (res && res.status == 200) {
                    let result = res.data.data;

                    let client = new OSS({
                        accessKeyId: result.accessKeyId,
                        accessKeySecret: result.accessKeySecret,
                        stsToken: result.securityToken,
                        // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                        region: result.region,
                        bucket: result.bucket
                    });

                    // storeAs可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
                    // file可以自定义为File对象、Blob数据以及OSS Buffer。
                    storeAs = result.path + '/' + uuidStr + '.' + file.name.split('.')[1];

                    let resultIndex = _this.files.findIndex(function (item) {
                        return item.uuidStr === uuidStr;
                    });

                    if (resultIndex >= 0) {
                        _this.files[resultIndex].storeAsStr = storeAs;
                    }
                    console.log('-2');
                    console.log(_this.files);

                    setTimeout(() => {
                        client
                            .multipartUpload(storeAs, file, {
                                progress: (p, checkpoint) => {
                                    let index = -1;
                                    let uploadProgress = Math.floor(p * 100);
                                    if (checkpoint && checkpoint.file) {
                                        index = _this.files.findIndex(function (item) {
                                            return item.storeAsStr === checkpoint.name;
                                        });

                                        // 更新上传进度
                                        if (index >= 0) {
                                            _this.files[index].uploadProgress = uploadProgress;
                                            _this.$forceUpdate();
                                        }
                                    }
                                }
                            })
                            .then(function (result) {
                                // storeAs - 阿里返回图片路径
                                console.log('-3');

                                console.log(result.name);
                                console.log(_this.files);

                                let tmpIndex = _this.files.findIndex(function (item) {
                                    return item.storeAsStr === result.name;
                                });
                                console.log('----' + tmpIndex);

                                if (tmpIndex >= 0) {
                                    _this.files[tmpIndex].fileName = file.name;
                                    _this.files[tmpIndex].ossPath = storeAs;
                                    _this.files[tmpIndex].uploadProgress = 100;
                                    _this.checkFileExtension(_this.files[tmpIndex]);
                                    _this.setTxtOverFlowStyle(tmpIndex);
                                    _this.$forceUpdate();
                                }
                            })
                            .catch(function (err) {
                                var errData = JSON.parse(JSON.stringify(err));
                                console.log('errorCode ：' + errData.status);
                                if (errData) {
                                    console.log(errData);
                                }

                                console.log(err);
                            });
                    }, 500);
                } else {
                    this.files[this.currentFileIndex].delFlag = true;
                    this.$forceUpdate();
                }
            });
        },
        checkLawsuitCase() {
            if (!this.queryCaseForm.caseNo) {
                this.isCaseExist = false;
                return;
            }
            this.instance.get('/lawsuitCase/findLawsuitCase/' + this.queryCaseForm.caseNo, { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.isCaseExist = data.data;
                }
            });
        },
        currentData(currentItem, currentIndex) {
            this.currentFileIndex = currentIndex;
        },
        handleExceed(files, fileList, e, e1) {
            console.log(files);
            console.log(fileList);
            console.log(e);
            console.log(e1);
            console.log('------------限制');

            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        getCaseFileType() {
            this.instance.get('/lawsuitCase/material/getCaseFileType', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseFileTypeObj = {};
                    this.caseFileTypeOptions = data.data;
                    data.data.forEach((item) => {
                        this.caseFileTypeObj[item.code] = item.name;
                    });
                }
            });
        },
        getCause() {
            this.instance.get('/lawsuitCase/getCause', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.causeOptions = data.data;
                }
            });
        },
        getProductType() {
            this.instance.get('/lawsuitCase/getProductType', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.productTypeOptions = data.data;
                }
            });
        },
        getInsureTypeOptions() {
            this.instance.get('/lawsuitCase/getInsureTypeName', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.insureTypeOptions = data.data;
                }
            });
        },
        allocationUserChange(val) {
            this.addCaseForm.distributeHandler = this.allocationUserObj[val] || '';
        },
        enterCaseDialog() {
            this.queryCaseForm = {
                caseNo: ''
            };
            this.queryCaseData = {
                policyNo: null,
                productType: null,
                insureTypeName: null,
                accidentCityDistrict: null,
                nameOfDeliveryMan: null
            };
            this.addCaseForm = {
                caseNo: '',
                caseName: '',
                courtCaseNo: '',
                plaintiff: '',
                lawsuitAmount: '',
                lawsuitCause: '',
                distributeHandler: '',
                distributeHandlerUser: '',
                situation: '',
                accidentProvince: '',
                accidentCity: '',
                accidentDistrict: ''
            };
            this.files = [
                {
                    typeCode: '',
                    typeName: '占位',
                    fileName: '',
                    ossPath: '',
                    flag: false,
                    isPreview: false
                }
            ];
            this.enterFlag = true;
            this.$nextTick(() => {
                this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
                this.$refs.queryCaseRef && this.$refs.queryCaseRef.resetFields();
            });
        },
        resetCaseData() {
            this.queryCaseForm = {
                caseNo: ''
            };
            this.queryCaseData = {
                policyNo: null,
                productType: null,
                insureTypeName: null,
                accidentCityDistrict: null,
                nameOfDeliveryMan: null
            };
            this.addCaseForm = {
                caseNo: '',
                caseName: '',
                courtCaseNo: '',
                plaintiff: '',
                lawsuitAmount: '',
                lawsuitCause: '',
                distributeHandler: '',
                distributeHandlerUser: '',
                situation: '',
                accidentProvince: '',
                accidentCity: '',
                accidentDistrict: ''
            };
            this.files = [
                {
                    typeCode: '',
                    typeName: '占位',
                    fileName: '',
                    ossPath: '',
                    flag: false,
                    isPreview: false
                }
            ];
            this.queryCaseFlag = false;
            this.enterFlag = false;
            this.$nextTick(() => {
                this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
                this.$refs.queryCaseRef && this.$refs.queryCaseRef.resetFields();
            });
        },
        getAllocationUser() {
            this.instance.post('/lawsuitCase/distributeCaseUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.allocationUserOptions = data.data;
                }
            });
        },
        enterCase(submitFlag) {
            if (submitFlag) {
                if (!this.queryCaseFlag && !this.manualEdit) {
                    this.$message.error('请点击查询按钮，查询理赔报案案件号是否正确！');
                    return;
                }
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let isCheckFileArr = [];
                        let isCheckTypeArr = [];
                        this.files.forEach((item, index) => {
                            if (item.fileName && !item.typeCode) {
                                isCheckFileArr.push(true);
                            }
                            if (item.typeCode && !item.fileName) {
                                isCheckTypeArr.push(true);
                            }
                        });

                        if (isCheckFileArr.length > 0) {
                            this.$message.error('请选择附件类型！');
                            return;
                        }
                        if (isCheckTypeArr.length > 0) {
                            this.$message.error('请上传附件！');
                            return;
                        }

                        this.addNewCase();
                    }
                });
            } else {
                this.closeDiag();
            }
        },
        searchMeiTuanCase() {
            this.queryInputCase = false;
            this.$refs.queryCaseRef.validate((valid) => {
                if (valid) {
                    this.queryCaseData = null;
                    this.instance
                        .get(`/lawsuitCase/getMeiTuanCase/${this.queryCaseForm.caseNo}`, {
                            params: {}
                        })
                        .then((res) => {
                            let data = res.data;
                            if (data.code == 1 && data.data) {
                                this.queryCaseFlag = true;
                                this.queryCaseData = data.data;
                                this.addCaseForm.caseNo = data.data.caseNo;
                            } else {
                                queryCaseRuleFlag = true;
                                this.$refs.queryCaseRef.validate();
                                queryCaseRuleFlag = false;
                                this.isCaseExist = false;
                            }
                        });
                } else {
                    this.queryInputCase = true;
                }
            });
        },
        manualEntry(manualFlag) {
            this.manualEdit = manualFlag;
            if (manualFlag) {
                this.queryInputCase = false;
                this.$refs.queryCaseRef && this.$refs.queryCaseRef.resetFields();
                queryCaseRuleFlag = false;
                this.checkLawsuitCase();
            }
        },
        addNewCase() {
            let tmpFiles = JSON.parse(JSON.stringify(this.files));
            let filesData = [];
            tmpFiles.forEach((item, index) => {
                if (item.ossPath) {
                    filesData.push(item);
                }
            });

            if (this.manualEdit) {
                this.addCaseForm.caseNo = this.queryCaseForm.caseNo;
            }

            this.addCaseForm.files = filesData;
            let submitAddCaseData;
            if (this.manualEdit) {
                submitAddCaseData = { ...this.queryCaseData, ...this.addCaseForm };
                let name1 = this.addCaseForm.province.split('-')[0];
                let name2 = this.addCaseForm.city.split('-')[0];
                let name3 = this.addCaseForm.district.split('-')[0];
                submitAddCaseData.accidentCityDistrict = name1 + name2 + name3;
                submitAddCaseData.accidentProvince = name1;
                submitAddCaseData.accidentCity = name2;
                submitAddCaseData.accidentDistrict = name3;
            } else {
                submitAddCaseData = { ...this.addCaseForm, ...this.queryCaseData };
            }
            if (this.creatCaseFlag) {
                return;
            }
            this.creatCaseFlag = true;
            this.instance.post('/lawsuitCase/addCase', submitAddCaseData).then((res) => {
                this.creatCaseFlag = false;
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('录入案件成功！');
                    this.$emit('searchCasePage', true);
                    if (!this.addCaseForm.distributeHandlerUser) {
                        setTimeout(() => {
                            this.$emit('openNewTab', data.data.caseId);
                        }, 1000);
                    }
                    this.closeDiag();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        closeDiag() {
            this.files.forEach((item, index) => {
                if (this.$refs['upload' + index][0]) {
                    this.$refs['upload' + index][0].clearFiles();
                } else {
                    this.$refs['upload' + index] && this.$refs['upload' + index].clearFiles();
                }
            });

            this.$emit('switchDialog', false);
            this.resetCaseData();
        },
        delFile(fileItem, fileIndex) {
            let uuidIndex = this.files.findIndex(function (item) {
                return item.uuidStr === fileItem.uuidStr;
            });

            if (uuidIndex >= 0) {
                if (this.$refs['upload' + uuidIndex][0]) {
                    this.$refs['upload' + uuidIndex][0].clearFiles();
                } else {
                    this.$refs['upload' + uuidIndex] && this.$refs['upload' + uuidIndex].clearFiles();
                }

                if (this.files.length > 1) {
                    this.files.splice(uuidIndex, 1);
                } else {
                    this.files = [
                        {
                            typeCode: '',
                            typeName: '占位',
                            fileName: '',
                            ossPath: '',
                            flag: false,
                            isPreview: false
                        }
                    ];
                }
            } else {
                console.log('删除失败！');
            }
        },
        addFile() {
            let tmpFileItem = {
                typeCode: '',
                typeName: '占位',
                fileName: '',
                ossPath: '',
                flag: false,
                isPreview: false
            };
            this.files.push(tmpFileItem);
            console.log('添加附件：');
            console.log(this.files);

            // 57e79daa8d28e58ab8d6cb28e470e4c4.jpg
            // lawsuit/img/test/ff1b52b9-e41c-4b75-a362-8675ef28d76d.jpg
            // 京东业务流程 V03.pdf
            // lawsuit/img/test/96687bda-0c97-40c4-96fb-be61001a8ace.pdf
            // REPORT-17c6121a9ba24e33b6900bfa63a11bea.pdf
            // lawsuit/img/test/3accf922-e61c-4515-abbc-1234d4f58a2b.pdf
            // this.checkFileExtension(tmpFileItem);
        },
        setTxtOverFlowStyle(currentFileIndex) {
            setTimeout(() => {
                let ellipsisEls = document.querySelectorAll('.ellipsisEnterCase' + currentFileIndex);
                ellipsisEls.forEach((element, index) => {
                    if (this.hasTextOverflowEllipsisAndOverflow(ellipsisEls[index])) {
                        element.classList.add('txtHoverStyle' + index);
                        this.files[currentFileIndex].flag = true;
                        this.$forceUpdate();
                    }
                });
            }, 100);
        },
        checkFileExtension(fileItem) {
            let strArr = fileItem.fileName.split('.');
            let fileExtension = strArr[strArr.length - 1];

            if (fileExtension == 'pdf' || this.fileTypeList.indexOf(fileExtension) >= 0) {
                fileItem.isPreview = true;
                fileItem.fileExtension = fileExtension;
                fileItem.previewPath = this.getOssFilePath(fileItem.ossPath);
                this.$forceUpdate();
            }
        },
        getOssFilePath(filePath) {
            let fileUrl = this.OSSClient.signatureUrl(filePath);
            return fileUrl;
        },
        openFileInNewTab(fileUrl) {
            var a = document.createElement('a');
            a.href = fileUrl;
            a.target = '_blank';
            a.click();
        },
        // 初始化OSS
        initOSSClient() {
            this.instance
                .get('/aliyunoss/access', { params: {} })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.OSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！-0');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    console.log('oss初始化失败-');
                    console.log('oss初始化失败，正在重试！-1');
                });
        },
        hasTextOverflowEllipsisAndOverflow(element) {
            // 获取元素的计算样式
            const computedStyle = window.getComputedStyle(element);

            // 检查是否设置了text-overflow: ellipsis
            if (computedStyle.textOverflow === 'ellipsis') {
                // 检查是否设置了overflow为非visible的值，并且white-space为nowrap
                if (computedStyle.overflow !== 'visible' && computedStyle.whiteSpace === 'nowrap') {
                    // 尝试使用scrollWidth（或offsetWidth）与clientWidth进行比较
                    // 如果scrollWidth（或offsetWidth）大于clientWidth，则说明文本溢出了
                    if (element.scrollWidth > element.clientWidth || element.offsetWidth > element.clientWidth) {
                        return true;
                    }
                }
            }

            return false;
        }
    },
    watch: {
        dialogShow: {
            handler(val) {
                this.dialogEnterFlag = val;
                val && this.initOSSClient();
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/table_public.css';
// 弹窗
.dialog_content {
    margin-top: -10px;
    .query_case_form {
        /deep/ .form_item {
            .el-form-item__label {
                white-space: nowrap;
                margin-left: -11px;
            }
        }
    }
    .case_show {
        margin-bottom: 20px;
        .title_col {
            .left_col {
                .query_title {
                    width: 138px !important;
                }
            }
        }
    }
    .el-form-item {
        margin-bottom: 0;
    }
    .el-row {
        margin-bottom: 20px;
        .title_col {
            .txt_box {
                display: inline-block;
            }
            .info_title {
                width: 100px;
                text-align: right;
                // border: 1px solid red;
            }
            .query_title {
                width: 128px;
            }
            /deep/ .shengfen {
                .el-form-item__content {
                    margin-left: 0 !important;
                }
            }
        }
    }
    .el-row:last-child {
        margin-bottom: 0;
    }
    .form_item {
        margin-bottom: 20px;
        .el-row {
            margin-bottom: 0px;
        }
    }
    .exist_case {
        /deep/ .el-input__inner {
            border: 1px solid #e6a23c;
        }
        .exist_col {
            position: relative;
            .exist_message {
                color: #e6a23c;
                font-size: 12px;
                position: absolute;
                bottom: -27px;
            }
        }
    }
    .form_content {
        padding: 0 10px;
    }
    .case_entry {
        margin-bottom: 20px;
    }
    .updateFile {
        position: relative;
        left: -2px;
        .el-col {
            line-height: 28px;
            position: relative;
            .del_btn {
                font-size: 20px;
                cursor: pointer;
            }
            .disabledBtn {
                width: 25px;
                height: 25px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                cursor: not-allowed;
            }
            .errorBtn {
                color: red;
            }
            .tooltipText {
                // width: 90%;
            }
            .progressSpan {
                position: absolute;
                top: 0;
                right: -36px;
                color: #409eff;
            }
        }
    }
    .uploadBtn {
        position: relative;
        left: -2px;
    }

    .preview_file {
        position: relative;
        .preview_image {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            /deep/ img {
                opacity: 0 !important;
            }
        }
    }
    // .province_item {
    //     /deep/ .el-form-item__content {
    //         // margin-left: 0 !important;
    //         // margin-left: 5px !important;
    //         .selectProvince {
    //             width: 30%;
    //             margin-right: 27px;
    //         }
    //         .selectProvince:last-child {
    //             margin-right: 0;
    //         }
    //         .el-form-item__error {
    //         }
    //     }
    // }
    .province_box {
        display: flex;
        justify-content: space-between;
        .province_item {
            width: 45%;
        }
        .province_item:nth-child(2) {
            width: 25%;
            margin-left: 0px;
            /deep/ .el-form-item__content {
                margin-left: 0 !important;
            }
        }
        .province_item:nth-child(3) {
            width: 25%;
            margin-left: 0px;
            /deep/ .el-form-item__content {
                margin-left: 0 !important;
            }
        }
    }
}
.errorBorder {
    /deep/ .el-input__inner {
        border: 1px solid red;
    }
}
</style>