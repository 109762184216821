<template>
    <div class="wrap">
        <div class="page_title"><b>我的工作台</b></div>
        <!--检索部分  -->
        <div class="search_box public_box">
            <el-form :inline="true" :model="navForm">
                <el-form-item label="保单号：">
                    <el-input class="width_250" v-model="navForm.policyNo" type="text" placeholder="请输入保单号" clearable></el-input>
                </el-form-item>
                <el-form-item label="投诉编号：">
                    <el-input class="width_230" v-model="navForm.complaintNumber" type="text" placeholder="请输入保单号" clearable></el-input>
                </el-form-item>
                <el-form-item label="投诉人姓名：">
                    <el-input class="width_150" v-model="navForm.complaintPerson" type="text" placeholder="请输入电话" clearable></el-input>
                </el-form-item>
                <el-form-item label="投诉人身份：">
                    <el-select class="width_120" v-model="navForm.complaintIdCard" clearable placeholder="请选择">
                        <el-option v-for="item in complaintIdCardOptions" :key="item.id" :label="item.dictName" :value="item.dictName"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="投诉电话1：">
                    <el-input class="width_150" v-model="navForm.complaintPhone" type="text" placeholder="请输入电话" clearable></el-input>
                </el-form-item>
                <el-form-item label="投诉电话2：">
                    <el-input class="width_150" v-model="navForm.complaintPhone2" type="text" placeholder="请输入电话" clearable></el-input>
                </el-form-item>
                <el-form-item label="录入日期：">
                    <el-row style="width: 350px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startInputDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endInputDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="案件状态：">
                    <el-select class="width_110" v-model="navForm.steps" clearable multiple placeholder="请选择">
                        <el-option v-for="item in stepOptions" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="协助处理人：">
                    <el-select class="width_110" v-model="navForm.assistingHandler" clearable placeholder="请选择">
                        <el-option v-for="item in handlerUserOptions" :key="item.id" :label="item.dictName" :value="item.dictName"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="责任机构：">
                    <el-select v-model="navForm.liableOrganization" placeholder="请选择" filterable clearable>
                        <el-option v-for="item in institutionOptions" :key="item.id" :label="item.dictName" :value="item.dictName"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="升级情况：">
                    <el-select v-model="navForm.upgradeSituation" placeholder="请选择" filterable clearable>
                        <el-option v-for="(item, index) in upgradeOptions" :key="index" :label="item.dictName" :value="item.dictName"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="margin-left: 10px" @click="searchCasePage(true)">查询</el-button>
                    <el-button type="primary" style="margin-left: 20px" v-if="rolesData['咨诉岗']" @click="switchDialog(true)">录入案件</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div class="main">
            <div class="table_box public_box">
                <el-table ref="multipleTable" :data="tableData" :height="tableHeight" :row-class-name="tableRowClassName" sortable="custom" @selection-change="tableSelection" @sort-change="tableSort" v-loading="loading">
                    <el-table-column prop="complaintNumber" fixed="left" label="投诉编号" width="230">
                        <template slot-scope="scope">
                            <div class="tooltip_text" style="display: inline-block" @click="openNewTab(scope.row.id)">
                                <overflow-txt :txt="scope.row.complaintNumber"></overflow-txt>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="recordDate" label="登记日期" width="130"></el-table-column>
                    <el-table-column prop="complaintChannel" label="渠道" width="140"></el-table-column>
                    <el-table-column prop="policyNo" label="保单号" width="200">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.policyNo"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="complaintPerson" label="投诉人"></el-table-column>
                    <el-table-column prop="complaintIdCard" label="投诉人身份"></el-table-column>
                    <el-table-column prop="assistingHandler" label="协助处理人"></el-table-column>
                    <el-table-column prop="liableOrganization" label="责任机构"></el-table-column>
                    <el-table-column prop="complaintPhone" label="投诉电话1" width="100"></el-table-column>
                    <el-table-column prop="complaintPhone2" label="投诉电话2" width="100"></el-table-column>
                    <el-table-column prop="complaintContent" label="投诉内容" width="200">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.complaintContent"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="complaintCause" label="诉求原因" width="200">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.complaintCause"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="complaintOrganization" label="涉诉机构"></el-table-column>
                    <el-table-column prop="liablePerson" label="责任人"></el-table-column>
                    <el-table-column prop="checkIndemnityPerson" label="核赔人"></el-table-column>
                    <el-table-column prop="province" label="省份"></el-table-column>
                    <!-- <el-table-column prop="upgradeFlag" label="是否升级">
                        <template slot-scope="scope">
                            <span>{{ String(scope.row.upgradeFlag) == 'true' ? '是' : String(scope.row.upgradeFlag) == 'false' ? '否' : '--' }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="upgradeSituation" label="升级情况" width="130"></el-table-column>
                    <el-table-column prop="closeCaseFlag" label="是否结案">
                        <template slot-scope="scope">
                            <span>{{ String(scope.row.closeCaseFlag) == 'true' ? '是' : String(scope.row.closeCaseFlag) == 'false' ? '否' : '--' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" width="200">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.remark"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="currentCaseStepDesc" fixed="right" label="案件状态"></el-table-column>
                    <el-table-column label="操作" fixed="right" width="230">
                        <template slot-scope="scope">
                            <el-button type="text" v-if="rolesData && (rolesData['咨诉岗'] || rolesData['核赔岗'] || rolesData['领导岗'])" :disabled="!scope.row.approveMenu && !scope.row.leaderApproveMenu && !scope.row.checkApproveMenu" @click="examineCase(scope.row)">审 核 </el-button>
                            <el-button type="text" v-if="rolesData && rolesData['咨诉岗']" :disabled="!scope.row.distributeMenu" @click="reassignCase(scope.row)">转 派</el-button>
                            <el-button type="text" v-if="rolesData && rolesData['处理岗']" :disabled="!scope.row.handleMenu" @click="openNewTab(scope.row.id)">处 理</el-button>
                            <el-button type="text" v-if="rolesData && rolesData['咨诉岗']" :disabled="!scope.row.deleteMenu" @click="updateCase(scope.row.id, null, 1)">删 除</el-button>
                            <el-button type="text" v-if="rolesData && rolesData['咨诉岗']" :disabled="!scope.row.specialEndMenu" @click="updateCase(scope.row.id, null, 2)">特殊办结</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.size" :total="navForm.total"> </el-pagination>
            </div>
        </div>
        <!-- 录入案件弹窗 -->
        <enter-case v-if="dialogShow" :dialogShow="dialogShow" @switchDialog="switchDialog" @openNewTab="openNewTab" @searchCasePage="searchCasePage"></enter-case>
        <!-- 删除 弹窗 -->
        <el-dialog :title="dialogTitleStr" :visible.sync="operateCaseFlag" :modal-append-to-body="false" width="500px" center>
            <span>{{ dialogStr }}</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="updateCase(null, false)">取 消</el-button>
                <el-button type="primary" @click="updateCase(null, true)">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 转派 弹窗 -->
        <el-dialog title="转派案件" :visible.sync="reassignFlag" :modal-append-to-body="false" width="400px" center>
            <el-form ref="reassignRef" :model="reassignForm" :rules="reassignRules" label-width="100px">
                <el-form-item label="转派人：" prop="distributeHandlerUser">
                    <el-select v-model="reassignForm.distributeHandlerUser" filterable clearable placeholder="请选择">
                        <el-option v-for="item in complaintCaseUserOptions" :key="item.userId" :label="item.nickName" :value="item.userName"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmReassign(false)">取 消</el-button>
                <el-button type="primary" @click="confirmReassign(true)">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import EnterCase from '../../components/complaint/enterCase.vue';

let queryCaseRuleFlag = false;
const validatePass = (rule, value, callback) => {
    if (value.length > 0 && queryCaseRuleFlag) {
        callback(new Error('未查询到案件号，请输入正确的理赔报案号重新查询'));
    } else {
        callback();
    }
};
export default {
    components: { EnterCase },
    data() {
        return {
            operateCaseType: null,
            operateCaseId: null,
            operateCaseFlag: false,
            dialogTitleStr: '删除案件',
            dialogStr: '是否确认删除当前案件？',
            delSelectData: [],
            handlerUserOptions: [],
            upgradeOptions: [],
            tableHeight: null,
            booleanOptions: [
                {
                    label: '是',
                    code: true
                },
                {
                    label: '否',
                    code: false
                }
            ],
            reassignFlag: false,
            complaintCaseUserOptions: [],
            complaintCaseUserObj: {},
            dialogShow: false,
            queryCaseFlag: false,
            enterFlag: false,
            loading: false,
            queryInputCase: false,
            navForm: {
                policyNo: '',
                startInputDate: '',
                complaintNumber: '',
                complaintPhone: '',
                complaintPerson: '',
                complaintIdCard: '',
                complaintPhone2: '',
                endInputDate: '',
                assigneeFlag: '',
                claimFlag: '',
                timeOutFlag: '',
                assistingHandler: '',
                upgradeSituation: '',
                steps: [],
                page: 1,
                size: 10,
                total: 0
            },
            caseStepOptions: [],
            checkStatusOptions: [],
            complaintIdCardOptions: [],
            tableData: [],
            reassignForm: {
                complaintId: '',
                distributeHandler: '',
                distributeHandlerUser: ''
            },
            reassignRules: {
                distributeHandlerUser: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }]
            },
            allocationUserOptions: [],
            allocationUserObj: {},
            causeOptions: [],
            rolesData: {},
            stepOptions: [],
            institutionOptions: []
        };
    },
    mounted() {
        // 动态计算table高度
        window.onresize = () => {
            this.calcHeight();
        };
        this.queryTableData();
        // 协助处理人
        this.getPublicEnum('COMPLAINT_ASSISTING_HANDLER', 'handlerUserOptions');
        // 责任机构
        this.getPublicEnum('COMPLAINT_LIABLE_PERSON', 'institutionOptions');
        // 获取升级情况
        this.getPublicEnum('COMPLAINT_UPGRADE_SITUATION', 'upgradeOptions');
        // 投诉人身份
        this.getPublicEnum('COMPLAINT_ID_CARD', 'complaintIdCardOptions');
        this.calcHeight();
        // 流程状态
        this.getAllCheckCaseStep();
        // 分配用户
        this.getComplaintCaseUser();
        // 用户角色
        this.getRoles();
        this.getStep();
    },
    methods: {
        calcHeight() {
            // 顶部tabs
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            // 搜索筛选项
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            // 分页
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 0;
            let guHeight = 175;

            this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;

            this.tableHeight = this.tableHeight < 200 ? 200 : this.tableHeight;
            this.$forceUpdate();
        },
        getStep() {
            this.instance.get('/complaintInfo/getStep', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.stepOptions = data.data;
                }
            });
        },
        getRoles() {
            this.rolesData = {};
            this.instance.get('/complaintInfo/getRoles', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        this.rolesData[item.name] = item;
                    });
                    let tmpTableData = JSON.parse(JSON.stringify(this.tableData));
                    this.tableData = tmpTableData;
                }
            });
        },
        tableSort(sortMap) {
            if (sortMap.order) {
                this.navForm.reverseOrder = sortMap.order === 'descending';
                this.navForm.orderKey = sortMap.prop == 'createTime' ? 'create_time' : 'court_time';
                this.navForm.orderKey = sortMap.prop == 'updateTime' ? 'update_time' : this.navForm.orderKey;
            } else {
                this.navForm.reverseOrder = '';
                this.navForm.orderKey = '';
            }
            this.searchCasePage(true);
        },
        tableSelection(selectData) {
            this.delSelectData = selectData;
        },
        tableRowClassName(rowData) {
            let strArr = ['监管咨询', '扬言监管', '监管正式'];
            if (strArr.indexOf(rowData.row.complaintChannel) >= 0) {
                return 'customizeRowStyle';
            }
        },
        updateCase(caseId, delFlag, type) {
            this.dialogTitleStr = type == 1 ? '删除案件' : '特殊办结';
            this.dialogStr = type == 1 ? '是否确认删除当前案件？' : '是否确认操作当前案件？';

            if (caseId) {
                this.operateCaseType = type;
                this.operateCaseId = caseId;
                this.operateCaseFlag = true;
                return;
            }

            let tmpIntenceUrl = this.operateCaseType == 1 ? '/complaintInfo/deleteComplaint' : '/complaintInfo/specialEndComplaint';
            if (delFlag && this.operateCaseId) {
                this.instance.post(tmpIntenceUrl, { complaintId: this.operateCaseId }).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.queryTableData();
                        this.operateCaseFlag = false;
                        this.$message.success(type == 1 ? '删除成功！' : '操作成功！');
                    } else {
                        this.$message.error(data.message);
                    }
                });
            } else {
                this.operateCaseFlag = false;
            }
        },
        queryTableData() {
            if (this.$route.query && this.$route.query.noticeData) {
                let tmpNoticeData = JSON.parse(this.$route.query.noticeData);
                setTimeout(() => {
                    this.navForm.steps = tmpNoticeData.code;
                    // 列表数据
                    this.searchCasePage(true);
                }, 1000);
            } else {
                // 列表数据
                this.searchCasePage();
            }
        },
        // 按钮权限
        caseCreateBtnTree() {
            this.instance.post('/complaintInfo/getMenu', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.btnMenuData = {};
                    data.data.forEach((item) => {
                        this.btnMenuData[item.code] = item;
                    });
                }
            });
        },
        getAllCheckCaseStep() {
            this.instance.get('/lawsuitCase/getAllCheckCaseStep', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.checkStatusOptions = data.data;
                }
            });
        },
        searchCasePage(queryFlag) {
            if (queryFlag) {
                this.navForm.page = 1;
            }
            this.tableData = [];
            this.instance.post('/complaintInfo/pageWorkbench', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.tableData = data.data.list;
                    this.navForm.total = data.data.total;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        openNewTab(caseId, linkData) {
            let a = document.createElement('a');
            a.href = `${location.origin}/complaintManage/caseDetail?complaintId=${caseId}`;
            a.target = '_blank';
            if (linkData) {
                a.href += linkData;
            }
            a.click();
        },
        handleCurrentChange(val) {
            this.navForm.page = val;
            this.searchCasePage();
        },
        handleSizeChange(val) {
            this.navForm.size = val;
            this.searchCasePage();
        },
        switchDialog(switchFlag) {
            this.dialogShow = switchFlag;
        },
        getComplaintCaseUser() {
            this.instance.post('/complaintInfo/distributeComplaintCaseUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.complaintCaseUserOptions = data.data;
                    this.complaintCaseUserOptions.forEach((item) => {
                        this.complaintCaseUserObj[item.userName] = item.nickName;
                    });
                }
            });
        },
        examineCase(caseItem) {
            this.openNewTab(caseItem.id, '&examine=true');
        },
        reassignCase(caseItem) {
            this.reassignFlag = true;
            this.reassignForm = {
                complaintId: caseItem.id,
                distributeHandler: '',
                distributeHandlerUser: ''
            };
        },
        confirmReassign(confirmFlag) {
            if (!confirmFlag) {
                this.reassignFlag = false;
            } else {
                this.$refs.reassignRef.validate((valid) => {
                    if (valid) {
                        this.distributeComplaint();
                    }
                });
            }
        },
        distributeComplaint() {
            this.reassignForm.distributeHandler = this.complaintCaseUserObj[this.reassignForm.distributeHandlerUser];
            this.instance.post('/complaintInfo/distributeComplaint', this.reassignForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('转派成功！');
                    this.reassignFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.searchCasePage();
            });
        }
    }
};
</script>
<style>
@import '../../assets/css/table_public.css';
</style>
<style lang="less" scoped>
.search_box {
    padding-bottom: 0;
    .el-form {
        .datePickerWidth {
            width: 250px;
        }
    }
}
.table_box {
    .tooltip_text {
        cursor: pointer;
        color: #409eff;
    }
    .winRate {
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-right: 5px;
    }
    .greenColor {
        background: #67c23a;
    }
    .redColor {
        background: red;
    }
    /deep/ .customizeRowStyle {
        color: red;
    }
}
.dialog_content {
    .title_col {
        margin-bottom: 10px;
        .txt_box {
            display: inline-block;
        }
        .info_title {
            width: 100px;
            text-align: right;
            // border: 1px solid red;
        }
        .query_title {
            width: 128px;
        }
    }
}
</style>