<template>
    <div class="case_card card_bottom-padding caseInfo">
        <el-row class="title_row">
            <el-col :span="12">
                <div class="card_title">案件信息</div>
            </el-col>
        </el-row>
        <div class="card_content">
            <el-row>
                <el-col :span="24">
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">事故编号：{{ caseDetailData && caseDetailData.accidentNumber ? caseDetailData.accidentNumber : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">保司报案号：{{ caseDetailData && caseDetailData.insurerReportNumber ? caseDetailData.insurerReportNumber : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">我方车牌：{{ caseDetailData && caseDetailData.vehiclePlateNumber ? caseDetailData.vehiclePlateNumber : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">责任情况：{{ caseDetailData && caseDetailData.responsibilityStatus ? caseDetailData.responsibilityStatus : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">我方车架号：{{ caseDetailData && caseDetailData.vehicleChassisNumber ? caseDetailData.vehicleChassisNumber : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">所属公司：{{ caseDetailData && caseDetailData.affiliatedCompany ? caseDetailData.affiliatedCompany : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">运营公司：{{ caseDetailData && caseDetailData.operatingCompany ? caseDetailData.operatingCompany : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">事故类型：{{ caseDetailData && caseDetailData.accidentType ? caseDetailData.accidentType : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">事故状态：{{ caseDetailData && caseDetailData.accidentStatus ? caseDetailData.accidentStatus : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">出险日期：{{ caseDetailData && caseDetailData.incidentDate ? caseDetailData.incidentDate : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">报案日期：{{ caseDetailData && caseDetailData.reportDate ? caseDetailData.reportDate : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">是否人伤：{{ caseDetailData && String(caseDetailData.isInjury) == '1' ? '是' : caseDetailData && String(caseDetailData.isInjury) == '0' ? '否' : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">人伤类型：{{ caseDetailData && caseDetailData.injuryType ? caseDetailData.injuryType : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">结案时间：{{ caseDetailData && caseDetailData.closureTime ? caseDetailData.closureTime : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">已决总金额：{{ caseDetailData && caseDetailData.resolvedTotalAmount ? caseDetailData.resolvedTotalAmount : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">我方驾驶员姓名：{{ caseDetailData && caseDetailData.myDriverName ? caseDetailData.myDriverName : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">对方车牌：{{ caseDetailData && caseDetailData.thirdPartyVehiclePlateNumber ? caseDetailData.thirdPartyVehiclePlateNumber : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">伤者姓名：{{ caseDetailData && caseDetailData.injuredPersonName ? caseDetailData.injuredPersonName : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">伤者联系方式：{{ caseDetailData && caseDetailData.injuredPersonContact ? caseDetailData.injuredPersonContact : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">保司结案时间：{{ caseDetailData && caseDetailData.insurerClosureTime ? caseDetailData.insurerClosureTime : '--' }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        caseDetailData: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            loading: false
        };
    },
    mounted() {},
    methods: {}
};
</script>
<style lang="less" scoped>
@import '../../assets/css/table_public.css';
@import '../../assets/css/caseCard.css';
.caseInfo {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
    .card_content {
        .content_row {
            .title {
                font-weight: 600;
            }
            .col_title {
                // font-weight: 600;
                max-width: 80px;
            }
            .col_txt {
                line-height: 22px;
                word-break: break-all;
                // border: 1px solid red;
                .edit_input {
                    width: 160px !important;
                }
                .inputNo {
                    width: 200px !important;
                }
            }
            .winRate {
                display: inline-block;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                margin-right: 5px;
                position: relative;
                top: 1px;
            }
            .greenColor {
                background: #67c23a;
            }
            .redColor {
                background: red;
            }
        }
    }
}
</style>