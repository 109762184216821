<template>
    <div class="wrap">
        <div class="task_page" v-show="!caseInfo">
            <div class="page_title"><b>环球车享质检</b></div>
            <!--检索部分  -->
            <div class="search_box public_box">
                <el-form ref="searchForm" :model="navForm" :inline="true">
                    <el-form-item label="事故编号：" prop="accidentNumber">
                        <el-input class="width_150" v-model="navForm.accidentNumber" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="保司报案号：" prop="insurerReportNumber">
                        <el-input class="width_230" v-model="navForm.insurerReportNumber" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="我方车牌：" prop="vehiclePlateNumber">
                        <el-input class="width_130" v-model="navForm.vehiclePlateNumber" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="责任情况：" prop="responsibilityStatus">
                        <el-select class="width_140" v-model="navForm.responsibilityStatus" placeholder="请选择" clearable>
                            <template v-for="(item, index) in responsibilityStatusOptions">
                                <el-option :key="index" :label="item" :value="item"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="我方车架号：" prop="vehicleChassisNumber">
                        <el-input class="width_190" v-model="navForm.vehicleChassisNumber" placeholder="请输入省份" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="所属公司：" prop="affiliatedCompany">
                        <el-select class="width_140" v-model="navForm.affiliatedCompany" placeholder="请选择" clearable>
                            <template v-for="(item, index) in affiliatedCompanyOptions">
                                <el-option :key="index" :label="item" :value="item"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="运营公司：" prop="operatingCompany">
                        <el-select class="width_140" v-model="navForm.operatingCompany" placeholder="请选择" clearable>
                            <template v-for="(item, index) in operatingCompanyOptions">
                                <el-option :key="index" :label="item" :value="item"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="事故类型：" prop="accidentType">
                        <el-select class="width_140" v-model="navForm.accidentType" placeholder="请选择" clearable>
                            <template v-for="(item, index) in accidentTypeOptions">
                                <el-option :key="index" :label="item" :value="item"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="事故状态：" prop="accidentStatus">
                        <el-select class="width_140" v-model="navForm.accidentStatus" placeholder="请选择任务标签" clearable>
                            <template v-for="(item, index) in accidentStatusOptions">
                                <el-option :key="index" :label="item" :value="item"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="出险日期：" class="dateBox">
                        <el-row style="width: 330px">
                            <el-col :span="11">
                                <el-form-item prop="startIncidentDate">
                                    <el-date-picker placeholder="请选择日期" v-model="navForm.startIncidentDate" type="date" value-format="yyyy-MM-dd" style="width: 100%" clearable> </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-form-item prop="endIncidentDate">
                                    <el-date-picker placeholder="请选择日期" v-model="navForm.endIncidentDate" type="date" value-format="yyyy-MM-dd" style="width: 100%" clearable> </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="报案日期：" class="dateBox">
                        <el-row style="width: 330px">
                            <el-col :span="11">
                                <el-form-item prop="startReportDate">
                                    <el-date-picker placeholder="请选择日期" v-model="navForm.startReportDate" type="date" value-format="yyyy-MM-dd" style="width: 100%" clearable> </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-form-item prop="endReportDate">
                                    <el-date-picker placeholder="请选择日期" v-model="navForm.endReportDate" type="date" value-format="yyyy-MM-dd" style="width: 100%" clearable> </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="是否人伤：" prop="isInjury">
                        <el-select class="width_80" v-model="navForm.isInjury" placeholder="请选择" clearable>
                            <template v-for="(item, index) in options">
                                <el-option :key="index" :label="item.label" :value="item.value"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="人伤类型：" prop="injuryType">
                        <el-select class="width_140" v-model="navForm.injuryType" placeholder="请选择" clearable>
                            <template v-for="(item, index) in injuryTypeOptions">
                                <el-option :key="index" :label="item" :value="item"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否质检：" prop="quality">
                        <el-select class="width_80" v-model="navForm.quality" placeholder="请选择" clearable>
                            <template v-for="(item, index) in options">
                                <el-option :key="index" :label="item.label" :value="item.value"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="质检状态：" prop="qualityStatus">
                        <el-select class="width_140" v-model="navForm.qualityStatus" placeholder="请选择" clearable>
                            <el-option v-for="item in qualityInspectionOption" :key="item.metaData" :label="item.dictName" :value="item.metaData"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="车损风险标签：" prop="category3">
                        <el-select class="width_140" v-model="navForm.category3" placeholder="请选择" multiple clearable>
                            <template v-for="item in category3Option">
                                <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="人伤风险标签：" prop="injuryCategory2">
                        <el-select class="width_140" v-model="navForm.injuryCategory2" placeholder="请选择" multiple clearable>
                            <template v-for="item in riskTypeOption2">
                                <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="结案时间：" class="dateBox">
                        <el-row style="width: 450px">
                            <el-col :span="11">
                                <el-form-item prop="startClosureDate">
                                    <el-date-picker placeholder="请选择日期" v-model="navForm.startClosureDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" style="width: 100%" clearable> </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-form-item prop="endClosureDate">
                                    <el-date-picker placeholder="请选择日期" v-model="navForm.endClosureDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" style="width: 100%" clearable> </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="我方驾驶员姓名：" prop="myDriverName">
                        <el-input class="width_150" v-model.number="navForm.myDriverName" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="对方车牌：" prop="thirdPartyVehiclePlateNumber">
                        <el-input class="width_150" v-model.number="navForm.thirdPartyVehiclePlateNumber" placeholder="请输入" clearable></el-input>
                    </el-form-item>

                    <el-form-item label="已决总金额：" class="dateBox">
                        <el-row style="width: 330px">
                            <el-col :span="11">
                                <el-form-item prop="geResolvedTotalAmount">
                                    <el-input class="width_150" v-model.number="navForm.geResolvedTotalAmount" placeholder="请输入" clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-form-item prop="leResolvedTotalAmount">
                                    <el-input class="width_150" v-model.number="navForm.leResolvedTotalAmount" placeholder="请输入" clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="伤者姓名：" prop="injuredPersonName">
                        <el-input class="width_150" v-model.number="navForm.injuredPersonName" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="伤者联系方式：" prop="injuredPersonContact">
                        <el-input class="width_150" v-model.number="navForm.injuredPersonContact" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="保司结案时间：" class="dateBox">
                        <el-row style="width: 450px">
                            <el-col :span="11">
                                <el-form-item prop="startInsurerClosureDate">
                                    <el-date-picker placeholder="请选择日期" v-model="navForm.startInsurerClosureDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" style="width: 100%" clearable> </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-form-item prop="endInsurerClosureDate">
                                    <el-date-picker placeholder="请选择日期" v-model="navForm.endInsurerClosureDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" style="width: 100%" clearable> </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchCaseQuality(true)" style="margin-left: 0.2rem">查询</el-button>
                        <el-button type="warning" @click="resetForm" style="margin-left: 0.2rem">重置</el-button>
                        <el-button type="primary" @click="enterDialog" style="margin-left: 0.2rem">录入</el-button>
                        <el-button type="primary" @click="download" style="margin-left: 0.2rem">下载</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 表格 -->
            <div class="main">
                <div class="table_box public_box">
                    <el-table ref="filterTable" :data="tableData" v-loading="loading" style="width: 100%" :height="tableHeight">
                        <el-table-column label="事故编号" fixed="left" width="210">
                            <template slot-scope="scope">
                                <!-- <div class="tooltipText" @click="hrefDetail(scope.row.meituanCaseNo, scope.row.caseNo)"> -->
                                <div class="tooltipText" @click="qualityClick(scope.row, scope.$index)">
                                    <overflow-txt :txt="scope.row.accident.accidentNumber"></overflow-txt>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="accident.insurerReportNumber" label="保司报案号" fixed="left" width="210">
                            <template slot-scope="scope">
                                <div>
                                    <overflow-txt :txt="scope.row.accident.insurerReportNumber"></overflow-txt>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="accident.vehiclePlateNumber" label="我方车牌" width="120"></el-table-column>
                        <el-table-column prop="accident.responsibilityStatus" label="责任情况" width="100"></el-table-column>
                        <el-table-column prop="accident.vehicleChassisNumber" label="我方车架号" width="160"></el-table-column>
                        <el-table-column prop="vehicleQuality" label="车损质检状态" width="100">
                            <template slot-scope="scope">
                                <span>{{ scope.row.vehicleQuality ? '已质检' : '未质检' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="injuryQuality" label="人伤质检状态" width="100">
                            <template slot-scope="scope">
                                <span>{{ scope.row.injuryQuality ? '已质检' : '未质检' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="category3" label="车损质检结果" width="100"></el-table-column>
                        <el-table-column prop="injuryCategory1" label="人伤质检结果" width="160"></el-table-column>
                        <el-table-column prop="accident.affiliatedCompany" label="所属公司" width="160">
                            <template slot-scope="scope">
                                <div>
                                    <overflow-txt :txt="scope.row.accident.affiliatedCompany"></overflow-txt>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="accident.operatingCompany" label="运营公司" width="160">
                            <template slot-scope="scope">
                                <div>
                                    <overflow-txt :txt="scope.row.accident.operatingCompany"></overflow-txt>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="accident.accidentType" label="事故类型"></el-table-column>
                        <el-table-column prop="accident.accidentStatus" label="事故状态"></el-table-column>
                        <el-table-column prop="accident.incidentDate" label="出险日期" width="150"></el-table-column>
                        <el-table-column prop="accident.reportDate" label="报案日期" width="150"></el-table-column>
                        <el-table-column prop="accident.isInjury" label="是否人伤">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.accident.isInjury ? '是' : '否' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="accident.injuryType" label="人伤类型"></el-table-column>
                        <el-table-column prop="accident.closureTime" label="结案时间" width="150"></el-table-column>
                        <el-table-column prop="accident.resolvedTotalAmount" label="已决总金额"></el-table-column>
                        <el-table-column prop="accident.myDriverName" label="我方驾驶员姓名" width="120"></el-table-column>
                        <el-table-column prop="accident.thirdPartyVehiclePlateNumber" label="对方车牌" width="120"></el-table-column>
                        <el-table-column prop="accident.injuredPersonName" label="伤者姓名"></el-table-column>
                        <el-table-column prop="accident.injuredPersonContact" label="伤者联系方式" width="120"></el-table-column>
                        <el-table-column prop="accident.insurerClosureTime" label="保司结案时间" width="150"></el-table-column>
                        <el-table-column prop="accident.insurerClosureTime" label="是否质检" fixed="right">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.quality">已质检</el-tag>
                                <el-tag type="danger" v-else>未质检</el-tag>
                            </template>
                        </el-table-column>
                        <!-- quality -->
                        <el-table-column label="操作" fixed="right" width="140">
                            <template slot-scope="scope">
                                <span class="a_color" @click="qualityClick(scope.row, scope.$index)">开始质检</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页部分-->
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.size" :total="navForm.total"> </el-pagination>
                </div>
            </div>
        </div>
        <!-- 录入弹窗 -->
        <el-dialog title="录入案件" :visible.sync="dialogVisible" width="30%" append-to-body center :before-close="beforeClose1">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
                <!-- <el-form-item label="任务标签：" prop="tagCode">
                    <el-select v-model="ruleForm.tagCode" placeholder="请选择任务标签" style="width: 90%">
                        <template v-for="item in caseQualityTagOption">
                            <el-option :key="item.id" :label="item.dictName" :value="item.metaData"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="优先级：" prop="orderCode">
                    <el-select v-model="ruleForm.orderCode" placeholder="请选择优先级" style="width: 90%">
                        <template v-for="item in caseQualityOrderOption">
                            <el-option :key="item.id" :label="item.dictName" :value="item.metaData"></el-option>
                        </template>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="事故编号：" prop="desc">
                    <el-input style="width: 90%" type="textarea" v-model="ruleForm.desc"></el-input>
                    <p style="color: red">注：每行一个事故号</p>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="beforeClose1">取 消</el-button>
                <el-button type="primary" @click="submitRuleForm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import CaseInfo from '@/components/staging/index';
export default {
    components: {
        CaseInfo
    },
    data() {
        return {
            tableHeight: 450,
            navForm: {
                accidentNumber: '',
                accidentNumberList: [],
                insurerReportNumber: null,
                vehiclePlateNumber: null,
                responsibilityStatus: null,
                vehicleChassisNumber: null,
                affiliatedCompany: null,
                operatingCompany: null,
                accidentType: null,
                accidentStatus: null,
                startIncidentDate: null,
                endIncidentDate: null,
                startReportDate: null,
                endReportDate: null,
                isInjury: null,
                injuryType: null,
                startClosureDate: null,
                endClosureDate: null,
                geResolvedTotalAmount: null,
                leResolvedTotalAmount: null,
                myDriverName: null,
                thirdPartyVehiclePlateNumber: null,
                injuredPersonName: null,
                injuredPersonContact: null,
                startInsurerClosureDate: null,
                endInsurerClosureDate: null,
                quality: null,
                qualityStatus: null,
                category3: null,
                injuryCategory2: null,
                size: 10,
                page: 1,
                total: 0
            },
            ruleForm: {
                tagCode: '',
                orderCode: '',
                desc: '',
                caseNos: []
            },
            options: [
                { label: '是', value: 1 },
                { label: '否', value: 0 }
            ],
            responsibilityStatusOptions: [],
            affiliatedCompanyOptions: [],
            operatingCompanyOptions: [],
            accidentTypeOptions: [],
            accidentStatusOptions: [],
            injuryTypeOptions: [],
            qualityStatusOptions: [],
            category3Option: [],
            totalPage: 0,
            currentPage: 10,
            dialogVisible: false,
            loading: false,
            caseInfo: false,
            tableData: [],
            caseRowData: {},
            caseJobAggInfoVo: {},
            aggUserCaseJobVos: {},
            aggUserCaseJobVosOutside: {},
            statisticsFlag: false,
            dialogVisible: false,
            rules: {
                orderCode: [{ required: true, message: '请选择任务标签', trigger: 'change' }],
                tagCode: [{ required: true, message: '请选择优先级', trigger: 'change' }],
                desc: [{ required: true, message: '请填写案件号', trigger: 'blur' }]
            },
            caseQualityOrderOption: [],
            caseQualityTagOption: [],
            riskTypeOption: [],
            riskTypeOption2: [],
            userOptions: [],
            accidentTypeOption: [],
            tmpAccidentTypeOption: [],
            qualityInspectionOption: []
        };
    },
    mounted() {
        if (sessionStorage.getItem('queryForm')) {
            this.navForm = JSON.parse(sessionStorage.getItem('queryForm'));
            sessionStorage.removeItem('queryForm');
        }
        // 获取案件列表
        this.searchCaseQuality();
        // 优先级
        this.getCaseQualityOrder();
        // 任务标签
        this.getCaseQualityTag();
        this.getResponsibleExaminer();
        this.getCaseQualityHumanInjuryRisk();

        // 事故类型 枚举
        this.getPublicEnum('CASE_QUALITY_ACCIDENT_TYPE', 'accidentTypeOption');
        // 质检状态 枚举
        this.getPublicEnum('CASE_QUALITY_STATUS', 'qualityInspectionOption');
        // 人伤风险类型2 枚举
        this.getPublicEnum('CASE_QUALITY_HUMAN_INJURY_RISK_02', 'riskTypeOption2');
        //  车损三级
        this.getCategory3Options();

        // 责任情况
        this.getOptions('responsibilityStatus', 'responsibilityStatusOptions');
        // 所属公司
        this.getOptions('affiliatedCompany', 'affiliatedCompanyOptions');
        // 运营公司
        this.getOptions('operatingCompany', 'operatingCompanyOptions');
        // 事故类型
        this.getOptions('accidentType', 'accidentTypeOptions');
        // 事故状态
        this.getOptions('accidentStatus', 'accidentStatusOptions');
        // 人伤类型
        this.getOptions('injuryType', 'injuryTypeOptions');

        // this.calcTableHeight();
        // window.addEventListener('resize', this.calcTableHeight);
    },
    beforeDestroy() {
        // window.removeEventListener('resize', this.calcTableHeight);
    },
    methods: {
        calcTableHeight() {
            let heightHeight = document.querySelector('.header') ? document.querySelector('.header').clientHeight : 0;

            let iframeEl = document.querySelector('.iframe');
            let computedStyle = window.getComputedStyle(iframeEl);
            let paddingTop = parseFloat(computedStyle.paddingTop);
            let paddingBottom = parseFloat(computedStyle.paddingBottom);

            let publicBoxEl = document.querySelector('.public_box');
            let publicBoxElStyle = window.getComputedStyle(publicBoxEl);
            let publicBoxHeight = parseFloat(publicBoxElStyle.height);
            let publicBoxMarginTop = parseFloat(publicBoxElStyle.marginTop);
            let publicBoxPaddingTop = parseFloat(publicBoxElStyle.paddingTop);
            let publicBoxPaddingBottom = parseFloat(publicBoxElStyle.paddingBottom);

            let userSearchHeight = document.querySelector('.user_search') ? document.querySelector('.user_search').clientHeight : 0;

            let guHeight = 18 + 80;
            this.$nextTick(() => {
                const containerHeight = window.innerHeight - guHeight - heightHeight - paddingTop - paddingBottom - publicBoxMarginTop - publicBoxPaddingTop - publicBoxPaddingBottom - userSearchHeight - publicBoxHeight;
                this.tableHeight = containerHeight;
            });
        },
        hrefDetail(caseNo, oldCaseNo) {
            let url = 'https://www.jchunuo.com/fe/insurance-claim-service-platform/index.html#/detail/caseDetail/';
            if (oldCaseNo.indexOf('old') != -1) {
                url = 'https://awp.meituan.com/ffe/insurance-claim-platform/index/index.html?code=MGAr4r&state=dx_auth_interceptor#/case?caseId=';
            } else {
                url = 'https://www.jchunuo.com/fe/insurance-claim-service-platform/index.html#/detail/caseDetail/';
            }
            window.open(url + caseNo);
        },
        getOptions(keyStr, optionsStr) {
            this.instance.post(`/evcardQuality/groupField/${keyStr}`, {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this[optionsStr] = data.data;
                }
            });
        },
        getCategory3Options() {
            this.instance.get('/caseQuality/getCaseQualityCategory3?category2=出厂属性', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.category3Option = data.data;
                }
            });
        },
        qualityClick(rowData, rowIndex) {
            let rowIds = [];
            this.tableData.forEach((item) => {
                rowIds.push(item.id);
            });

            let queryData = { caseDetail: JSON.stringify({ id: rowData.id }), queryForm: JSON.stringify(this.navForm), rowIndex: rowIndex, rowIds: rowIds };
            this.$router.push({ path: '/staging/caseDetail', query: queryData });
        },
        download() {
            let queryForm = JSON.parse(JSON.stringify(this.navForm));
            this.instance({
                method: 'post',
                url: '/evcardQuality/downloadCaseQuality',
                data: queryForm,
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"text/csv;charset=UTF-8",
                }
            })
                .then((res) => {
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', '环球车享质检案件.xls'); //指定下载后的文件名，防跳转
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        qualityChange(val) {
            let tmpOption = [];
            this.navForm.accidentType = '';
            this.qualityInspectionOption.forEach((item) => {
                if (item.metaData == val) {
                    this.accidentTypeOption.forEach((items) => {
                        if (items.metaData == item.expandInfo) {
                            tmpOption.push(items);
                        }
                    });
                }
            });
            this.tmpAccidentTypeOption = tmpOption;
        },
        getCaseQualityHumanInjuryRisk() {
            this.instance.get('/caseQuality/getCaseQualityHumanInjuryRisk', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                }
            });
        },
        getResponsibleExaminer() {
            this.instance.post('/caseJobCheck/searchCommitJobUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        item.value = item.username;
                        item.label = item.nikeName;
                    });
                    this.userOptions = data.data;
                    this.userOptions.unshift({ value: 'all', label: '全部' });
                }
            });
        },
        beforeClose1() {
            this.dialogVisible = false;
            this.$refs.ruleForm.resetFields();
        },
        submitRuleForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (this.ruleForm.desc.length > 1) {
                        this.navForm.accidentNumberList = this.ruleForm.desc.split('\n');
                        this.searchCaseQuality(2);
                    }
                }
            });
        },
        importQualityCase() {
            if (this.ruleForm.desc.length > 1) {
                this.ruleForm.caseNos = this.ruleForm.desc.split('\n');

                this.instance.post('/caseQuality/importQualityCase', this.ruleForm).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        // this.$message.success('案件导入成功');
                        this.searchCaseQuality();
                        this.beforeClose1();
                    } else {
                        this.$message.error(data.message);
                    }
                });
            } else {
                this.$message.error('请输入案件号');
            }
        },
        resetForm() {
            this.$refs.searchForm && this.$refs.searchForm.resetFields();
        },
        searchCaseQuality(btnFlag) {
            let queryForm = JSON.parse(JSON.stringify(this.navForm));
            queryForm.page = btnFlag ? 1 : queryForm.page;
            queryForm.size = btnFlag ? 10 : queryForm.size;

            this.instance.post('/evcardQuality/searchCaseQuality', queryForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (btnFlag == 2) {
                        this.$message.success('案件导入成功');
                        this.beforeClose1();
                    }
                    this.tableData = data.data.list;
                    this.navForm.size = data.data.size;
                    this.navForm.page = data.data.current;
                    this.navForm.total = data.data.total;
                    this.$nextTick(() => {
                        this.$refs.filterTable.doLayout();
                    });
                }
            });
        },
        getCaseQualityOrder() {
            this.instance.get('/caseQuality/getCaseQualityOrder', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        item.metaData = Number(item.metaData);
                    });
                    this.caseQualityOrderOption = data.data;
                }
            });
        },
        getCaseQualityTag() {
            this.instance.get('/caseQuality/getCaseQualityTag', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        item.metaData = Number(item.metaData);
                    });
                    this.caseQualityTagOption = data.data;
                }
            });
        },
        enterDialog() {
            this.dialogVisible = true;
        },
        handleSizeChange(val) {
            this.navForm.size = val;
            this.searchCaseQuality();
        },
        handleCurrentChange(val) {
            this.navForm.page = val;
            this.searchCaseQuality();
        },
        clearSelectVal(val) {}
    }
};
</script>
<style lang="less">
@import '../../assets/css/table_public.css';

#chartDom1,
#chartDom2,
#chartDom3 {
    width: 400px;
    height: 200px;
}
.show_txt {
    padding-top: 17px;
    font-size: 14px;
    font-weight: 800;
    line-height: 23px;
    margin-left: 10%;
    position: absolute;
    left: 46%;
}
.task_cards {
    display: flex;
    justify-content: space-around;
}
.task_card {
    width: 340px;
    height: 85px;
    line-height: 85px;
    background: rgb(0, 192, 239);
    border-radius: 5px;
}
.card2 {
    background: rgb(0, 160, 87);
}
.card3 {
    background: rgb(241, 155, 18);
}
.task_card p {
    color: white;
    font-size: 20px;
    text-align: center;
}
.task_card .card_txt {
    line-height: normal;
    font-size: 12px;
    color: black;
}
.task_card .card_font {
    color: white;
    font-size: 20px;
}
.task_card .card_hot {
    color: blue;
    font-weight: 700;
    font-size: 36px;
}
.fl {
    float: left;
}
.fr {
    float: right;
}
.clearFix {
}
.clearFix:after {
    content: '';
    display: block;
    clear: both;
}
.dialog-left {
    width: 49%;
    min-width: 350px;
}
.dialog-left .el-form-item__content {
    text-align: left;
}
.dialog-right .right-div {
    margin-bottom: 20px;
}
/deep/.el-form-item__content {
    margin-left: 80px !important;
}
.none-left {
    margin-left: 0 !important;
}
.none-left .el-form-item__error {
    margin-left: -120px;
}
.none-left label {
    text-align: left !important;
}
.newLeft {
    .right-div {
        margin-bottom: 5px;
    }
}
.resetLineHeight /deep/.el-input__inner {
    line-height: 1px !important;
}
.zhi-dialog .el-dialog__footer {
    margin-top: -20px;
}
</style>