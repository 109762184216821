<template>
    <div class="case_card card_bottom-padding caseInfo">
        <el-row class="title_row">
            <el-col :span="12">
                <div class="card_title">案件材料</div>
            </el-col>
        </el-row>
        <div class="card_content">
            <!-- 案件材料 -->
            <div class="card_types autoP">
                <ul class="clearfix">
                    <li class="card-col_title autoP fl" v-if="!caseDetailData || (caseDetailData && caseDetailData.materialsList && Object.keys(caseDetailData.materialsList).length == 0)">暂无案件材料</li>
                    <template v-if="showCaseList.length > 0">
                        <template v-for="(item, index) in showCaseList">
                            <li :class="index === liActiveIndex ? 'li_activeBorder card_type fl' : 'card_type fl'" :key="index" v-if="item.files && item.files.length > 0" @click="switchCarousel(item, index)">
                                <div>
                                    <span>{{ item.files ? item.files.length : '0' }}</span>
                                    <p>{{ item.parentName }}</p>
                                </div>
                            </li>
                        </template>
                    </template>
                </ul>
            </div>
            <!-- 轮播图 -->
            <div class="preview_parent" v-if="imgUrlList && imgUrlList.length > 0">
                <i :class="showPopup ? 'showLeft left-btn el-icon-arrow-left' : 'left-btn el-icon-arrow-left'" @click="imgLeft()"></i>
                <i :class="showPopup ? 'showRight right-btn el-icon-arrow-right' : 'right-btn el-icon-arrow-right'" @click="imgRight()"></i>
                <div :class="showPopup ? 'showPreview preview-box' : 'preview-box'" v-if="imgUrlList.length > 0">
                    <ul class="Img_ul">
                        <template v-for="(item, i) in imgUrlList">
                            <li class="Img_li" :style="imgStyle" :key="item.id" @click="changeImg(item, i)">
                                <!-- 图片样式 -->
                                <template v-if="item.isImg && item.url">
                                    <img :class="i === imgActiveIndex ? 'img_activeBorder' : ''" :src="item.url" @click="previewImg(item.url, i)" />
                                </template>
                                <!-- 非图片后缀样式 -->
                                <template v-else-if="item.url">
                                    <div :class="i === imgActiveIndex ? 'img_activeBorder img_none' : 'img_none'" @click="previewImg(item.url, i)">
                                        <p style="margin: 4px 0">{{ item.suffix }}</p>
                                        <p>!&nbsp;非图片文件</p>
                                        <p style="margin-top: 5px">无法展示</p>
                                    </div>
                                </template>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 图片预览 弹窗 -->
        <!-- :close-on-click-modal="false" -->
        <el-dialog :class="{ preview_dialog: 1, preview_artificial: $route.path == '/caseInnerQuality/innerQualityManage' }" :visible.sync="imgDialogVisible" center append-to-body width="1066px" :before-close="closeImgDialog">
            <div class="zhanwei" style="height: 15px; width: 10px"></div>
            <div :class="wineCheckFlag ? 'dialog_content wine_content  clearfix' : 'dialog_content suspendContent clearfix'">
                <div class="content_left fl">
                    <el-carousel
                        class="content_carousel"
                        ref="carouselRef"
                        trigger="click"
                        arrow="never"
                        :autoplay="false"
                        :initial-index="initialIndex"
                        indicator-position="none"
                        @change="carouselChange"
                        v-loading="identifyLoading"
                        element-loading-text="识别中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                    >
                        <el-carousel-item v-for="(imgUrl, imgIndex) in imgUrlList" :key="imgIndex">
                            <img-deal :id="'imgDeal' + imgIndex" ref="imgDeal" :url="imgUrl.url" :wineCheckFlag="wineCheckFlag"></img-deal>
                        </el-carousel-item>
                        <el-button class="carousel_btn carousel_left" type="info" icon="el-icon-arrow-left" circle @click="previewPrev"></el-button>
                        <el-button class="carousel_btn carousel_right" type="info" icon="el-icon-arrow-right" circle @click="previewNext"></el-button>
                    </el-carousel>
                </div>
                <div class="content_right fr">
                    <ul :class="imgUrlList[initialIndex] && imgUrlList[initialIndex].repeatCaseNos && imgUrlList[initialIndex].repeatCaseNos.length > 1 ? 'types_ul-over types_ul clearfix' : 'types_ul clearfix'">
                        <template v-for="(item, index) in showCaseList">
                            <li :key="index">
                                <p class="thumbnail_title">{{ item ? item.parentName : '' }}（{{ item && item.files ? item.files.length : '0' }}）</p>
                                <ul :class="thumbnailActiveIndex == index ? 'thumbnail_ul thumbnailActive clearfix' : 'thumbnail_ul clearfix'">
                                    <template v-if="item.files && item.files.length > 0">
                                        <!-- 右侧缩略图 -->
                                        <li :class="subsetIndex == itemsIndex && thumbnailActiveIndex == index ? 'subsetActive Img_li fl' : 'Img_li fl'" v-for="(items, itemsIndex) in item.files" :key="itemsIndex" @click="subsetChange(items, itemsIndex, item, index)">
                                            <!-- 图片样式 -->
                                            <template v-if="items.isImg && items.url">
                                                <img :src="items.url" />
                                            </template>
                                            <!-- 非图片后缀样式 -->
                                            <template v-else-if="items.url">
                                                <div class="img_none">
                                                    <p style="margin: 4px 0">{{ items.suffix }}</p>
                                                    <p>!&nbsp;非图片文件</p>
                                                    <p style="margin-top: 5px">无法展示</p>
                                                </div>
                                            </template>
                                            <div class="imgModel" v-if="items.isModel">模型分析</div>
                                        </li>
                                    </template>
                                </ul>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import OSS from 'ali-oss';
import { v4 as uuidv4 } from 'uuid';
import ImgDeal from '@/components/ImgDeal.vue';
let tmpList = [
    {
        parentCode: 10,
        parentName: '损失证明',
        enumList: [
            { parentCode: 10, parentName: '损失证明', code: 201, desc: '内物破损整体', algoMaterialsEnum: { code: 30, desc: '内物破损整体' }, similarityScore: 0.97 },
            { parentCode: 10, parentName: '损失证明', code: 204, desc: '内物破损部位', algoMaterialsEnum: { code: 50, desc: '破损内包装' }, similarityScore: 0.97 },
            { parentCode: 10, parentName: '损失证明', code: 2041, desc: '内包装完好', algoMaterialsEnum: { code: 51, desc: '未破损内包装' }, similarityScore: 0.97 },
            { parentCode: 10, parentName: '损失证明', code: 205, desc: '外包装', algoMaterialsEnum: { code: 80, desc: '破损外包装' }, similarityScore: 0.98 },
            { parentCode: 10, parentName: '损失证明', code: 2051, desc: '外包装完好', algoMaterialsEnum: { code: 91, desc: '完好外包装' }, similarityScore: 0.98 },
            { parentCode: 10, parentName: '损失证明', code: 206, desc: '销毁证明', algoMaterialsEnum: { code: 20, desc: '销毁证明' }, similarityScore: 0.99 },
            { parentCode: 10, parentName: '损失证明', code: 207, desc: '销毁证明', algoMaterialsEnum: { code: 70, desc: '酒水缠绕膜或气泡柱' }, similarityScore: 0.99 }
        ],
        remark: '所有内物破损整体、内物破损部位、内包装完好、外包装、外包装完好、销毁证明、销毁证明',
        files: [
            {
                id: 6048,
                caseNo: '14044989JDV012355026174JDV012355026174-1-1-',
                materialPath: 'case-materials/claim-business-product-sys/file/20230825154313/%E5%86%85%E7%89%A9%E5%9B%BE%E7%89%87.png',
                materialParentType: 10,
                materialType: 9901,
                materialTypeName: '其他材料',
                materialName: '%E5%86%85%E7%89%A9%E5%9B%BE%E7%89%87.png',
                isNegotiateMaterials: false,
                negotiateId: null,
                materialOriginalPath: 'https://uad-claim.s3.cn-north-1.jdcloud-oss.com/claim-business-product-sys/file/20230825154313/内物图片.png',
                materialRepeatIds: '',
                repeatCaseNos: null,
                invoiceNo: null,
                invoiceCode: null,
                isPs: false,
                confidence: null,
                signHash: '',
                wineAuthenticationResult: null,
                similarCaseNos: null,
                similarCaseNoList: null,
                shippingDate: null,
                isOverHalfYear: false,
                url: 'http://cargo-tpa-hangzhou.oss-cn-hangzhou.aliyuncs.com/case-materials/claim-business-product-sys/file/20230825154313/%25E5%2586%2585%25E7%2589%25A9%25E5%259B%25BE%25E7%2589%2587.png?OSSAccessKeyId=STS.NTjcUawN4ZufANDEwiQ7BqPhF&Expires=1738836480&Signature=pYN27DSpO8ssIB0hlXAxkkyzLFI%3D&security-token=CAISvgJ1q6Ft5B2yfSjIr5ffKO%2FVmpEV7beNQ2j1oXc8XbhunpXDpDz2IH9LfndqB%2B8Wt%2F40mGhX7%2F8Tlqp0UIQdheUk024qvPpt6gqET9frma7ctM4p6vCMHWyUFGSIvqv7aPn4S9XwY%2Bqkb0u%2B%2BAZ43br9c0fJPTXnS%2Brr76RqddMKRAK1QCNbDdNNXGtYpdQdKGHaOITGUHeooBKJUho161Aj1Tohtv3vk5fA0HeE0g2mkN1yjp%2FqP52pY%2FNrOJpCSNqv1IR0DPGej3QOsUYVpP4s1%2FAcpm6e78v6Cl1Q8giANPHP%2B9ZkIRRidgye2kDVbm%2BWf5RazQ%2Bz%2F%2Byfo34lVWplyih6uVuO6n9lCTHY18e4SMdq9k63pvSlHLe7PW3an9yVSk3khRTPBIG%2BB35IaHXuToXDnvSiVZ%2B7WPbtuMkagAFfRwOSNHV723ahiwonStyyRZxvn6RKVvM6fALGisUHp41kLpuUyeAbJngTMGYs7BDkoPRKUqjbaBv020GmVJZV%2BQi4fyIrwCyJtmvBCAuYUElJhmggZpAjQ0DH3VEgzBgz3pUXc7oOViBxVuq1KSrRXejEcykFV1UYProtQDrDiCAA',
                suffix: 'png',
                isImg: true
            }
        ]
    },

    {
        parentCode: 40,
        parentName: '承运前证明',
        enumList: [{ parentCode: 40, parentName: '承运前证明', code: 306, desc: '承运前证明', algoMaterialsEnum: null, similarityScore: 0.99 }],
        remark: '所有承运前证明',
        files: [
            {
                id: 6049,
                caseNo: '14044989JDV012355026174JDV012355026174-1-1-',
                materialPath: 'case-materials/claim-business-product-sys/file/20230825154250/%E5%86%85%E7%89%A9%E5%9B%BE%E7%89%87.png',
                materialParentType: 40,
                materialType: 9901,
                materialTypeName: '其他材料',
                materialName: '%E5%86%85%E7%89%A9%E5%9B%BE%E7%89%87.png',
                isNegotiateMaterials: false,
                negotiateId: null,
                materialOriginalPath: 'https://uad-claim.s3.cn-north-1.jdcloud-oss.com/claim-business-product-sys/file/20230825154250/内物图片.png',
                materialRepeatIds: '',
                repeatCaseNos: null,
                invoiceNo: null,
                invoiceCode: null,
                isPs: false,
                confidence: null,
                signHash: '',
                wineAuthenticationResult: null,
                similarCaseNos: null,
                similarCaseNoList: null,
                shippingDate: null,
                isOverHalfYear: false,
                url: 'http://cargo-tpa-hangzhou.oss-cn-hangzhou.aliyuncs.com/case-materials/claim-business-product-sys/file/20230825154250/%25E5%2586%2585%25E7%2589%25A9%25E5%259B%25BE%25E7%2589%2587.png?OSSAccessKeyId=STS.NTjcUawN4ZufANDEwiQ7BqPhF&Expires=1738836480&Signature=yvONkuI6ki9c2xs6gI9WkS3PvMk%3D&security-token=CAISvgJ1q6Ft5B2yfSjIr5ffKO%2FVmpEV7beNQ2j1oXc8XbhunpXDpDz2IH9LfndqB%2B8Wt%2F40mGhX7%2F8Tlqp0UIQdheUk024qvPpt6gqET9frma7ctM4p6vCMHWyUFGSIvqv7aPn4S9XwY%2Bqkb0u%2B%2BAZ43br9c0fJPTXnS%2Brr76RqddMKRAK1QCNbDdNNXGtYpdQdKGHaOITGUHeooBKJUho161Aj1Tohtv3vk5fA0HeE0g2mkN1yjp%2FqP52pY%2FNrOJpCSNqv1IR0DPGej3QOsUYVpP4s1%2FAcpm6e78v6Cl1Q8giANPHP%2B9ZkIRRidgye2kDVbm%2BWf5RazQ%2Bz%2F%2Byfo34lVWplyih6uVuO6n9lCTHY18e4SMdq9k63pvSlHLe7PW3an9yVSk3khRTPBIG%2BB35IaHXuToXDnvSiVZ%2B7WPbtuMkagAFfRwOSNHV723ahiwonStyyRZxvn6RKVvM6fALGisUHp41kLpuUyeAbJngTMGYs7BDkoPRKUqjbaBv020GmVJZV%2BQi4fyIrwCyJtmvBCAuYUElJhmggZpAjQ0DH3VEgzBgz3pUXc7oOViBxVuq1KSrRXejEcykFV1UYProtQDrDiCAA',
                suffix: 'png',
                isImg: true
            }
        ]
    },
    {
        parentCode: 50,
        parentName: '运单证明',
        enumList: [{ parentCode: 50, parentName: '运单证明', code: 302, desc: '运单', algoMaterialsEnum: { code: 100, desc: '快递运单' }, similarityScore: 0.98 }],
        remark: '所有运单',
        files: [
            {
                id: 6046,
                caseNo: '14044989JDV012355026174JDV012355026174-1-1-',
                materialPath: 'case-materials/claim-business-product-sys/file/20230825154242/%E5%86%85%E7%89%A9%E5%9B%BE%E7%89%87.png',
                materialParentType: 50,
                materialType: 9901,
                materialTypeName: '其他材料',
                materialName: '%E5%86%85%E7%89%A9%E5%9B%BE%E7%89%87.png',
                isNegotiateMaterials: false,
                negotiateId: null,
                materialOriginalPath: 'https://uad-claim.s3.cn-north-1.jdcloud-oss.com/claim-business-product-sys/file/20230825154242/内物图片.png',
                materialRepeatIds: '',
                repeatCaseNos: null,
                invoiceNo: null,
                invoiceCode: null,
                isPs: false,
                confidence: null,
                signHash: '',
                wineAuthenticationResult: null,
                similarCaseNos: null,
                similarCaseNoList: null,
                shippingDate: null,
                isOverHalfYear: false,
                url: 'http://cargo-tpa-hangzhou.oss-cn-hangzhou.aliyuncs.com/case-materials/claim-business-product-sys/file/20230825154242/%25E5%2586%2585%25E7%2589%25A9%25E5%259B%25BE%25E7%2589%2587.png?OSSAccessKeyId=STS.NTjcUawN4ZufANDEwiQ7BqPhF&Expires=1738836480&Signature=K8Mn3CACfct36Q0gOmfsRw9rsZ0%3D&security-token=CAISvgJ1q6Ft5B2yfSjIr5ffKO%2FVmpEV7beNQ2j1oXc8XbhunpXDpDz2IH9LfndqB%2B8Wt%2F40mGhX7%2F8Tlqp0UIQdheUk024qvPpt6gqET9frma7ctM4p6vCMHWyUFGSIvqv7aPn4S9XwY%2Bqkb0u%2B%2BAZ43br9c0fJPTXnS%2Brr76RqddMKRAK1QCNbDdNNXGtYpdQdKGHaOITGUHeooBKJUho161Aj1Tohtv3vk5fA0HeE0g2mkN1yjp%2FqP52pY%2FNrOJpCSNqv1IR0DPGej3QOsUYVpP4s1%2FAcpm6e78v6Cl1Q8giANPHP%2B9ZkIRRidgye2kDVbm%2BWf5RazQ%2Bz%2F%2Byfo34lVWplyih6uVuO6n9lCTHY18e4SMdq9k63pvSlHLe7PW3an9yVSk3khRTPBIG%2BB35IaHXuToXDnvSiVZ%2B7WPbtuMkagAFfRwOSNHV723ahiwonStyyRZxvn6RKVvM6fALGisUHp41kLpuUyeAbJngTMGYs7BDkoPRKUqjbaBv020GmVJZV%2BQi4fyIrwCyJtmvBCAuYUElJhmggZpAjQ0DH3VEgzBgz3pUXc7oOViBxVuq1KSrRXejEcykFV1UYProtQDrDiCAA',
                suffix: 'png',
                isImg: true
            }
        ]
    }
];
export default {
    name: 'CaseMateria',
    props: {
        caseDetailData: {
            type: Object,
            default() {
                return {};
            }
        },
        btnMenuData: {
            type: Object,
            default: null
        }
    },
    components: {
        ImgDeal
    },
    data() {
        return {
            showPopup: false,
            previewShow: false,
            imgDialogVisible: false,
            identifyLoading: false,
            wineCheckFlag: false,
            imgActiveIndex: 0,
            imgDistance: 0, // 移动的距离
            allDistance: 0, // 总移动距离
            initialIndex: 0,
            liActiveIndex: 0,
            thumbnailActiveIndex: 0,
            subsetIndex: 0,
            showCaseListRealityLength: 0,
            showCaseList: [],
            imgUrlList: [],
            audioTypeList: ['mp3', 'mp4', 'wav', 'wma', 'aac', 'flac', 'ogg'],
            fileTypeList: ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif']
        };
    },
    mounted() {
        // /SG2408020698/定损单/C2D559EE048C4DE98C94983D6A2392D7.png
    },
    computed: {
        imgStyle() {
            return {
                transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
            };
        }
    },
    methods: {
        // 初始化OSS
        initOSSClient() {
            this.instance
                .get('/aliyunoss/access', { params: {} })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.OSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            // bucket: data.data.bucket
                            bucket: 'evcard-claims-data'
                        });

                        this.initMaterialsList(this.OSSClient);
                    } else {
                        console.log('oss初始化失败，正在重试！');
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        previewFile(caseItem, itemIndex) {
            this.previewData = caseItem;
            if (this.fileTypeList.indexOf(caseItem.fileExtension) != -1) {
                this.$refs['elImage' + itemIndex].clickHandler();
            } else if (caseItem.fileExtension == 'pdf') {
                this.openFileInNewTab(this.getOssFilePath(caseItem.ossPath));
            } else if (this.audioTypeList.indexOf(caseItem.fileExtension) != -1) {
                this.previewFlag = true;
            } else {
                console.log(caseItem.fileExtension);
            }
        },
        //初始化案件索赔材料
        initMaterialsList(ossClient) {
            if (this.caseDetailData && this.caseDetailData.tmpMaterialsList && this.caseDetailData.tmpMaterialsList.length > 0) {
                this.showCaseListRealityLength = 0;
                this.caseDetailData.tmpMaterialsList.forEach((item, key) => {
                    this.showCaseListRealityLength++;
                    item.files &&
                        item.files.forEach((rowData, rowIndex) => {
                            // 图片后缀
                            rowData.suffix = this.$$getFileSuffix(rowData.materialName);
                            let suffix = this.$$getFileSuffix(rowData.materialName);
                            if (suffix.length > 6 && suffix.indexOf('%') != -1) {
                                let tmpSuffix = suffix.split('%')[0];
                                suffix = tmpSuffix ? (tmpSuffix == '.com' ? true : false) : '未识别';
                            }
                            rowData.suffix = suffix;
                            rowData.isImg = Boolean(suffix != -1);

                            // 图片路径
                            // /SG2408020698/定损单/C2D559EE048C4DE98C94983D6A2392D7.png
                            rowData.url = this.OSSClient.signatureUrl(`/${item.accidentNumber}/${item.parentName}/${rowData.materialName}`);
                        });
                });

                this.showCaseList = this.caseDetailData.tmpMaterialsList;

                if (this.showCaseList.length > 0) {
                    this.switchCarousel(this.showCaseList[0], 0);
                    this.liActiveIndex = 0;
                }
            }
        },
        initCaseMaterial(materialList) {
            // 文件后缀是否可以预览
            let tmpMaterialList = JSON.parse(JSON.stringify(materialList));
            tmpMaterialList.forEach((fileItem, fileIndex) => {
                fileItem.loading = false;
                let strArr = fileItem.fileName.split('.');
                let fileExtension = strArr[strArr.length - 1];
                fileExtension = fileExtension.toLowerCase();

                if (fileItem.typeCode != 99 || this.fileTypeList.indexOf(fileExtension) >= 0) {
                    fileItem.isPreview = true;
                    fileItem.fileExtension = fileExtension;
                    fileItem.previewPath = this.getOssFilePath(fileItem.ossPath);
                }
            });

            this.materialTable = tmpMaterialList;
        },
        imgLeft() {
            if (this.imgActiveIndex > 0) {
                this.imgActiveIndex--; // 索引值-1
            }
            if (this.imgActiveIndex >= 4) {
                var index = 0;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < 33) {
                        // 移动次数(33次)
                        this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 10);
            }
        },
        imgRight() {
            if (this.imgActiveIndex < this.imgUrlList.length - 1) {
                this.allDistance = 0;
                this.imgActiveIndex++;
                if (this.imgActiveIndex >= 5) {
                    this.allDistance = -66 * (this.imgActiveIndex - 4);
                    var index = 0;
                    const temp = window.setInterval(() => {
                        if (index < 33) {
                            this.imgDistance -= 2; // 每次向右移动的距离
                            index++;
                            return;
                        } else {
                            window.clearInterval(temp);
                        }
                    }, 10);
                }
            } else if (this.imgActiveIndex === this.imgUrlList.length - 1) {
                // 到达最后一张图片，再点击跳转回第一张
                this.imgActiveIndex = 0;
                var index = 0;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < Math.abs(this.allDistance / 2)) {
                        // 取绝对值再除
                        this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 1);
            }
        },
        carouselChange(rowIndex) {
            this.titleIndex = rowIndex + 1;
            this.subsetIndex = rowIndex;
            // 当前图片index
            this.tmpPreviewIndex = rowIndex;
        },
        changeImg(item, index) {
            this.imgActiveIndex = index;
            this.imageUrl = item;
            this.previewShow = true;
        },
        closeImgDialog() {
            this.imgDialogVisible = false;
            this.wineCheckFlag = false;
            this.identifyLoading = false;
            this.checkedForm = {
                wineType: '',
                materialId: '',
                code: ''
            };
            this.queryWineFlag && this.$emit('getCaseDetail');
            this.queryWineFlag = false;
        },
        previewImg(src, i) {
            this.previewUrl = src;
            this.imgActiveIndex = i;
            this.imgDialogVisible = true;
            this.subsetIndex = i;
            this.tmpPreviewIndex = i;
            this.initialIndex = i;
            // 右侧案件列表标题
            this.$refs.carouselRef && this.$refs.carouselRef.setActiveItem(i);
        },
        previewPrev() {
            if (this.tmpPreviewIndex <= 0) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex - 1 < 0) {
                    let tmpIndex = this.showCaseList[0].files ? this.showCaseList[0].files.length - 1 : 0;
                    this.subsetChange(null, tmpIndex, this.showCaseList[this.showCaseList.length - 1], this.showCaseList.length - 1);
                } else {
                    let tmpIndex = this.showCaseList[this.thumbnailActiveIndex - 1].files ? this.showCaseList[this.thumbnailActiveIndex - 1].files.length - 1 : 0;
                    this.subsetChange(null, tmpIndex, this.showCaseList[this.thumbnailActiveIndex - 1], this.thumbnailActiveIndex - 1);
                }
            } else {
                this.$refs.carouselRef.prev();
                this.initialIndex--;
            }
            this.switchFlag = true;
        },
        previewNext() {
            if (this.tmpPreviewIndex == this.imgUrlList.length - 1) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex + 1 >= this.showCaseListRealityLength) {
                    this.subsetChange(null, 0, this.showCaseList[0], 0);
                } else {
                    this.subsetChange(null, 0, this.showCaseList[this.thumbnailActiveIndex + 1], this.thumbnailActiveIndex + 1);
                }
            } else {
                this.$refs.carouselRef.next();
                this.initialIndex++;
            }
            this.switchFlag = true;
        },
        subsetChange(items, itemsIndex, item, index) {
            this.subsetIndex = itemsIndex;
            this.thumbnailActiveIndex = index;
            this.switchCarousel(item, index);
            this.initialIndex = itemsIndex;
            this.$refs.carouselRef && this.$refs.carouselRef.setActiveItem(itemsIndex);
        },
        switchCarousel(data, index) {
            this.imgDistance = 0;
            this.imgActiveIndex = 0;
            this.liActiveIndex = index;
            this.titleIndex = 1;
            this.thumbnailActiveIndex = index;

            if (data && data.files && data.files.length > 0) {
                this.imgUrlList = data.files;
            }
        }
    },
    watch: {
        caseDetailData: {
            handler(val) {
                val && val.tmpMaterialsList && this.initOSSClient();
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/caseCard.css';
.caseInfo {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
}

.card_content {
    padding-bottom: 20px;
    .content_row {
        .col_title {
            text-align: right;
        }
        .col_txt {
            line-height: 22px;
            .select_user {
                margin-left: 10px;
                margin-block: 5px;
            }
        }
    }

    .tab_content {
        ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: #fff;
        }
        .noneTableData::before {
            height: 0px !important;
        }
        .content_row {
            margin-bottom: 25px;
            .project_row {
                display: flex;
                align-items: center;
            }
            .dy_row {
                // position: relative;
                .col_title {
                    position: absolute;
                    top: 5px;
                }
                // .col_txt {
                //     position: absolute;
                //     right: 0;
                //     top: 0;
                // }
            }
            .situation_box {
                padding: 20px;
                height: 300px;
                overflow-y: scroll;
                border: 1px solid #dcdcdc;
                .situation_item {
                    margin-bottom: 15px;
                }
                .situation_item:last-child {
                    margin-bottom: 0;
                }
            }
            .txt_box {
                display: inline-block;
            }
            .click_txt {
                margin-left: 20px;
                color: #409eff;
                cursor: pointer;
                border-bottom: 1px solid #409eff;
            }
            .info_title {
                width: 100px;
                text-align: right;
                // border: 1px solid red;
            }
            .info_input {
                width: 80%;
            }
            .align_col {
                display: flex;
                align-items: top;
            }
        }
        .btn_group {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }
        // 编辑
        .edit_content {
            .content_row {
                display: flex;
                align-items: center;
            }
            .exception_row {
                display: inline !important;
            }
        }
        .preview_file {
            position: relative;
            xc span {
                cursor: pointer;
                color: #409eff;
            }
            .preview_image {
                // width: 100%;
                // height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 0;
                /deep/ img {
                    opacity: 0 !important;
                }
            }
        }
    }
}
.preview_dialog {
    .preview_content {
        .el-row {
            margin-bottom: 10px;
        }
    }
    .preview_audio {
        margin-top: 20px;
        border-top: 1px solid #dfdfdf;
        padding-top: 20px;
        audio {
            width: 100%;
            height: 30px;
        }
    }
    .right_table {
        max-height: 300px;
    }
    .case_list {
        .el-tabs--top {
            position: relative;
            z-index: 2;
            background: #fff;
        }
        .right_table {
            margin-top: -43px;
            position: relative;
            z-index: 1;
        }
    }
    .suspendContent {
        position: relative;
        .modelBtn {
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: 50px;
            right: 100px;
            cursor: pointer;
            border-radius: 50%;
            border: 1px solid #f85008;
            z-index: 8;
            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 20px;
                height: 20px;
            }
        }
        .suspend_box {
            position: absolute;
            width: 250px;
            height: 150px;
            z-index: 10;
            background: #fff;
            border-radius: 2px;
            box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
            left: 483px;
            top: 445px;
            .suspend_head {
                position: relative;
                padding: 10px;
                cursor: move;
                .closeBtn {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    cursor: pointer;
                    width: 15px;
                    height: 15px;
                }
            }
            .suspend_content {
                padding: 10px;
                padding-top: 0px;
                max-height: 110px;
                overflow-y: auto;
                p {
                    line-height: 22px;
                }
                .foldTxt {
                    font-weight: 600;
                }
            }
        }
    }
}

.upload_dialog {
    .dialog_content {
        .upload_btn {
            text-align: center;
        }
        .files_box {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            .del_icon {
                cursor: pointer;
                padding: 4px;
            }
        }
    }
}
</style>